import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNotification } from "../context/NotificationContext";

interface AdminAuthContextType {
  isAdminAuthenticated: boolean;
  adminLogin: (username: string, password: string) => Promise<boolean>;
  adminLogout: () => void;
}

const AdminAuthContext = createContext<AdminAuthContextType | undefined>(
  undefined
);

const ADMIN_AUTH_KEY = "adminAuthState";

export const AdminAuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(() => {
    // Initialize auth state from localStorage
    const savedAuthState = localStorage.getItem(ADMIN_AUTH_KEY);
    return savedAuthState === "true";
  });
  const [cookies, setCookie] = useCookies(["adminToken"]);
  const { showNotification } = useNotification();

  const navigate = useNavigate();

  // Update localStorage when auth state changes
  useEffect(() => {
    localStorage.setItem(ADMIN_AUTH_KEY, isAdminAuthenticated.toString());
  }, [isAdminAuthenticated]);

  // In a real application, this would be handled by your backend

  const adminLogin = async (username: string, password: string) => {
    try {
      const response = await axios.post(
        "https://fapi.pshealth.in/pos-health/api/admin/login",
        { email: username, password },
        { withCredentials: true }
      );
      // console.log("admin token : ", response);

      if (response.data?.token) {
        const token = response.data.token;
        setIsAdminAuthenticated(true);
        const expiryDate = new Date();
        expiryDate.setHours(expiryDate.getHours() + 1);

        // Store the token in an HTTP-only cookie with a 1 hour expiration
        setCookie("adminToken", token, { expires: expiryDate });

        showNotification("Login successful!", "success");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Admin login failed:", error);
      return false;
    }
  };

  const adminLogout = () => {
    setIsAdminAuthenticated(false);
    localStorage.removeItem(ADMIN_AUTH_KEY);
    navigate("/admin/login");
  };

  return (
    <AdminAuthContext.Provider
      value={{ isAdminAuthenticated, adminLogin, adminLogout }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};
export const useAdminAuth = () => {
  const context = useContext(AdminAuthContext);
  if (context === undefined) {
    throw new Error("useAdminAuth must be used within an AdminAuthProvider");
  }
  return context;
};
