import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { usePrivacyPolicy } from '../../context/PrivacyPolicyContext';
 
const PrivacyPolicyViewer: React.FC = () => {
  const { privacyPolicy } = usePrivacyPolicy();
 
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Privacy Policy
      </Typography>
      <Paper sx={{ mt: 3, p: 3 }}>
        <div dangerouslySetInnerHTML={{ __html: privacyPolicy.content }} />
      </Paper>
    </Box>
  );
};
 
export default PrivacyPolicyViewer;