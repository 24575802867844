import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { AccountCircle, ShoppingCart, Event, Home } from "@mui/icons-material"; // Import Material UI Icons
import ProfileForm from "./auth/ProfileForm";
import PatientAppointment from "./auth/PatientAppointment";
import PatientHomeCollection from "./auth/PatientHomeCollection";
import { usePatient } from "../context/PatientContext";
import PatientOrder from "./auth/PatientOrder";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [homeCollectionData, setHomeCollectionData] = useState([]);
  const [activeSection, setActiveSection] = useState("profile"); // State to track the active section
  const {
    getAppointmentInfo,
    getHomeCollectionInfo,
    getOrderInfo,
    fetchPatientData
  } = usePatient();
  const [cookies] = useCookies(["userToken"]); // Assuming the token is stored in cookies
  const [token, setToken] = useState();

  useEffect(() => {
    const fetchToken = () => {
      const userToken = cookies.userToken;
      if (userToken) {
        setToken(userToken);
      }
    };
    fetchToken();
  }, [cookies.userToken]);


  useEffect(() => {
    if (!token) return;
  
    const fetchProfileData = async () => {
      try {
        const patientData = await fetchPatientData(token);
        setUserData(patientData.Patient);
        // console.log("p--data ",patientData.Patient)
        // console.log("Fetching with token: ", token);
  
        const order = await getOrderInfo(token);
        setOrderData(order.ordersData);
        // console.log("Order info : ",order.orders);

        const appointmentData = await getAppointmentInfo(token);
        setAppointmentData(appointmentData.Appointment);
        console.log("Appointment info: ", appointmentData);
  
        const homeCollectionData = await getHomeCollectionInfo(token);
        setHomeCollectionData(homeCollectionData.HomeCollection);
        // console.log("Home collection: ", homeCollectionData);
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching profile data:", error);
        setLoading(false);
      }
    };
  
    fetchProfileData();
  }, [token]); 

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      {/* Sidebar */}
      <Box
        sx={{
          width: "200px",
          backgroundColor: "#f4f4f4",
          padding: 2,
          borderRight: "1px solid #ddd",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <List sx={{ padding: 0 }}>
          <ListItem
            button
            sx={{ padding: 1 }}
            onClick={() => setActiveSection("profile")}
          >
            <AccountCircle sx={{ marginRight: 1 }} />
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem
            button
            sx={{ padding: 1 }}
            onClick={() => setActiveSection("orders")}
          >
            <ShoppingCart sx={{ marginRight: 1 }} />
            <ListItemText primary="Orders" />
          </ListItem>
          <ListItem
            button
            sx={{ padding: 1 }}
            onClick={() => setActiveSection("appointments")}
          >
            <Event sx={{ marginRight: 1 }} />
            <ListItemText primary="Appointments" />
          </ListItem>
          <ListItem
            button
            sx={{ padding: 1 }}
            onClick={() => setActiveSection("homeCollection")}
          >
            <Home sx={{ marginRight: 1 }} />
            <ListItemText primary="Home Collection" />
          </ListItem>
        </List>
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, padding: 1 }}>
        <Typography variant="h5" gutterBottom>
          {activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}
        </Typography>

        {activeSection === "profile" && userData && (
          <Box>
            <Box sx={{ backgroundColor: "#fff" }}>
              <ProfileForm patientData={userData} />
            </Box>
          </Box>
        )}

        {activeSection === "orders" && orderData && (
          <Box sx={{ marginBottom: 2 }}>
           <PatientOrder orderData={orderData}/>
          </Box>
        )}

        {activeSection === "appointments" && appointmentData && (
          <Box sx={{ marginBottom: 2 }}>
            <PatientAppointment appointmentData={appointmentData} />
          </Box>
        )}

        {activeSection === "homeCollection" && homeCollectionData && (
          <Box sx={{ marginBottom: 2 }}>
            <PatientHomeCollection homeCollectionData={homeCollectionData}/>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
