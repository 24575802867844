import React, { createContext, useContext, useState, useEffect } from 'react';

export interface Doctor {
  _id: string;
  name: string;
  email?: string;
  phone: string;
  specialty: string;
  qualification: string;
  experience: number;
  about?: string;
  availabilityDays: string[];
  availabilityTime: string[];
  consultationFee: number;
  imgUrl?: string;
}

interface DoctorContextType {
  doctors: Doctor[];
  addDoctor: (doctor: Omit<Doctor, '_id'>) => Promise<void>;
  updateDoctor: (id: string, doctor: Partial<Doctor>) => Promise<void>;
  deleteDoctor: (id: string) => Promise<void>;
  getDoctorById: (id: string) => Doctor | undefined;
}

const DoctorContext = createContext<DoctorContextType | undefined>(undefined);

export const DoctorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);

  useEffect(() => {
    const fetchDoctorData = async () => {
      try {
        const response = await fetch('https://fapi.pshealth.in/pos-health/api/doctor/get-all');
        const data = await response.json();
        // console.log("data : ",data.doctors);
        setDoctors(data.doctors);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };
    fetchDoctorData();
  }, []);

  const addDoctor = async (doctorData: Omit<Doctor, '_id'>) => {
    try {
      const response = await fetch('https://fapi.pshealth.in/pos-health/api/doctor/add-new', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(doctorData),
      });
      const newDoctor = await response.json();
      setDoctors((prev=[]) => [...prev, newDoctor.doctor]);
    } catch (error) {
      console.error('Error adding doctor:', error);
    }
  };

  const updateDoctor = async (id: string, doctorData: Partial<Doctor>) => {
    try {
      await fetch(`https://fapi.pshealth.in/pos-health/api/doctor/update/${id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(doctorData),
      });
      setDoctors(prev => prev.map(doctor => (doctor._id === id ? { ...doctor, ...doctorData } : doctor)));
    } catch (error) {
      console.error('Error updating doctor:', error);
    }
  };

  const deleteDoctor = async (id: string) => {
    try {
      await fetch(`https://fapi.pshealth.in/pos-health/api/doctor/delete/${id}`, {
        method: 'DELETE',
      });
      setDoctors(prev => prev.filter(doctor => doctor._id !== id));
    } catch (error) {
      console.error('Error deleting doctor:', error);
    }
  };

  const getDoctorById = (id: string) => {
    return doctors.find(doctor => doctor._id === id)
  };

  return (
    <DoctorContext.Provider value={{ doctors, addDoctor, updateDoctor, deleteDoctor, getDoctorById }}>
      {children}
    </DoctorContext.Provider>
  );
};

export const useDoctor = () => {
  const context = useContext(DoctorContext);
  if (!context) {
    throw new Error('useDoctor must be used within a DoctorProvider');
  }
  return context;
};
