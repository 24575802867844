import React, { createContext, useContext, useState, useEffect } from "react";
import { HealthPackage } from "../data/initialPackages";
import { useCookies } from "react-cookie";

interface HealthPackageContextType {
  packages: HealthPackage[];
  addPackage: (pkg: HealthPackage) => void;
  updatePackage: (id: number, pkg: HealthPackage) => void;
  deletePackage: (id: number) => void;
  saveChanges: () => Promise<void>;
}

const HealthPackageContext = createContext<
  HealthPackageContextType | undefined
>(undefined);

export const useHealthPackage = () => {
  const context = useContext(HealthPackageContext);
  if (!context) {
    throw new Error(
      "useHealthPackage must be used within a HealthPackageProvider"
    );
  }
  return context;
};

export const HealthPackageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [packages, setPackages] = useState<HealthPackage[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [cookie] = useCookies(["adminToken"]);
  useEffect(() => {
    const fetchPackages = async () => {
      await fetch(
        "https://fapi.pshealth.in/pos-health/api/health-package/get-all"
      )
        .then((res) => res.json())
        .then((data) => {
          setPackages(data);
          // console.log('Fetched packages from API', data);
        });
    };

    fetchPackages();

    // console.log('Fetching packages from API', packages);
  }, []);

  const addPackage = async (pkg: HealthPackage) => {
    try {
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/health-package/add-new",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(pkg),
        }
      );
      const newPackage = await response.json();
      // console.log("new package : ",newPackage);
      setPackages((prev=[]) => [...prev, newPackage.newHealthPackage]);
    } catch (error) {
      console.error("Error adding package:", error);
    }
  };
  const updatePackage = async (id: number, updatedPkg: HealthPackage) => {
    try {
      await fetch(
        `https://fapi.pshealth.in/pos-health/api/health-package/update/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(updatedPkg),
        }
      );
      setPackages((prev) =>
        prev.map((pkg) => (pkg._id === id ? updatedPkg : pkg))
      );
    } catch (error) {
      console.error("Error updating package:", error);
    }
  };

  const deletePackage = async (id: number) => {
    try {
      await fetch(
        `https://fapi.pshealth.in/pos-health/api/health-package/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );
      setPackages((prev) => prev.filter((pkg) => pkg._id !== id));
    } catch (error) {
      console.error("Error deleting package:", error);
    }
  };

  const saveChanges = async () => {
    try {
      localStorage.setItem("healthPackages", JSON.stringify(packages));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <HealthPackageContext.Provider
      value={{
        packages,
        addPackage,
        updatePackage,
        deletePackage,
        saveChanges,
      }}
    >
      {children}
    </HealthPackageContext.Provider>
  );
};
