import React, { createContext, useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

export interface Appointment {
  _id: string;
  doctor: any;
  patient: any;
  reason: string;
  status: "pending" | "confirmed" | "cancelled";
  createdAt: string;
  isRead: boolean;
}

interface AppointmentContextType {
  appointments: Appointment[];
  addAppointment: (
    appointment: Omit<
      Appointment,
      "_id" | "status" | "createdAt" | "isRead" | "patient"
    >,
    token: any
  ) => Promise<void>;
  updateAppointmentStatus: (
    id: string,
    status: Appointment["status"]
  ) => Promise<void>;
  getAppointmentsByDoctorId: (doctorId: string) => Appointment[];
  markAppointmentAsRead: (id: string) => Promise<void>;
  getUnreadAppointmentsCount: () => number;
}

const AppointmentContext = createContext<AppointmentContextType | undefined>(
  undefined
);

export const AppointmentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [cookie] = useCookies(["adminToken"]);
  // Fetch all appointments from API
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await fetch(
          "https://fapi.pshealth.in/pos-health/api/appointment/get-all-request",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${cookie.adminToken}`,
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch appointments");

        const data = await response.json();
        setAppointments(data.data);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    };

    fetchAppointments();
  }, []);

  // Add an appointment (Send to API)
  const addAppointment = async (
    appointmentData: Omit<
      Appointment,
      "_id" | "status" | "createdAt" | "isRead" | "patient"
    >,
    token: any
  ) => {
    try {
      // const token = cookies.userAuth;
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/appointment/request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...appointmentData,
            status: "pending",
            isRead: false,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to add appointment");

      const newAppointment = await response.json();
      setAppointments((prev: any) => [...prev, newAppointment]);
    } catch (error) {
      console.error("Error adding appointment:", error);
    }
  };

  // Update appointment status via API
  const updateAppointmentStatus = async (
    id: string,
    status: Appointment["status"]
  ) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/appointment/update-status/${id}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ status }),
        }
      );

      if (!response.ok) throw new Error("Failed to update appointment status");

      setAppointments((prev = []) =>
        prev.map((appointment) =>
          appointment._id === id ? { ...appointment, status } : appointment
        )
      );
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

  // Mark appointment as read via API
  const markAppointmentAsRead = async (id: string) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/appointment/mark-read/${id}`,
        {
          method: "PATCH",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ isRead: true }),
        }
      );

      if (!response.ok) throw new Error("Failed to mark appointment as read");

      setAppointments((prev) =>
        prev.map((appointment) =>
          appointment._id === id
            ? { ...appointment, isRead: true }
            : appointment
        )
      );
    } catch (error) {
      console.error("Error marking appointment as read:", error);
    }
  };

  // Get appointments by doctor ID
  const getAppointmentsByDoctorId = (doctorId: string) => {
    return appointments.filter(
      (appointment) => appointment.doctor._id === doctorId
    );
  };

  // Get count of unread appointments
  const getUnreadAppointmentsCount = () => {
    return appointments?.filter((appointment) => !appointment.isRead).length;
  };

  const value = {
    appointments,
    addAppointment,
    updateAppointmentStatus,
    getAppointmentsByDoctorId,
    markAppointmentAsRead,
    getUnreadAppointmentsCount,
  };

  return (
    <AppointmentContext.Provider value={value}>
      {children}
    </AppointmentContext.Provider>
  );
};

export const useAppointment = () => {
  const context = useContext(AppointmentContext);
  if (context === undefined) {
    throw new Error(
      "useAppointment must be used within an AppointmentProvider"
    );
  }
  return context;
};
