import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Chip,
  Stack,
  InputAdornment,
  Alert,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { useHealthRisk } from "../../context/HealthRiskContext";
import { HealthRisk } from "../../data/healthRisk";

const HealthRiskManager: React.FC = () => {
  const { risks, addRisk, updateRisk, deleteRisk, saveChanges } =
    useHealthRisk();
  const [openDialog, setOpenDialog] = useState(false);
  const [editingRisk, setEditingRisk] = useState<any | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [formData, setFormData] = useState<HealthRisk>({
    _id: 0,
    categoryName: "",
    description: "",
    tests: [],
  });
  const [newTest, setNewTest] = useState("");
  const [test, setTest] = useState<any>([]);



  useEffect(() => {
    const fetchTest = async () => {
      await fetch("https://fapi.pshealth.in/pos-health/api/test/get-all-test")
        .then((res) => res.json())
        .then((data) => {
          setTest(data.tests);
        //   console.log("test data : ", data.tests);
        });
    };

    fetchTest();
  }, []);

  const handleOpenDialog = (pkg?: HealthRisk) => {
    if (pkg) {
      setEditingRisk(pkg._id);
      setFormData(pkg);
    } else {
      setEditingRisk(null);
      setFormData({
        _id: Math.max(0, ...risks.map((r) => r._id)) + 1,
        categoryName: "",
        description: "",
        tests: [],
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingRisk(null);
    setFormData({
      _id: 0,
      categoryName: "",
      description: "",
      tests: [],
    });
    setNewTest("");
  };

  const handleSave = () => {
    if (!formData.categoryName || !formData.description) {
      setSnackbar({
        open: true,
        message: "Please fill in all required fields",
        severity: "error",
      });
      return;
    }

    try {
      if (editingRisk) {
        updateRisk(editingRisk, formData);
        setSnackbar({
          open: true,
          message: "Health Risk updated successfully",
          severity: "success",
        });
      } else {
        console.log("form data : ",formData);
        addRisk(formData);
        setSnackbar({
          open: true,
          message: "Health Risk added successfully",
          severity: "success",
        });
      }
      handleCloseDialog();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error saving risk",
        severity: "error",
      });
    }
  };

  const handleDelete = (id: any) => {
    if (window.confirm("Are you sure you want to delete this Health Risk?")) {
      try {
        deleteRisk(id);
        setSnackbar({
          open: true,
          message: "Health Risk deleted successfully",
          severity: "success",
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Error deleting risk",
          severity: "error",
        });
      }
    }
  };

  const handleSaveChanges = async () => {
    try {
      await saveChanges();
      setSnackbar({
        open: true,
        message: "All changes saved successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error saving changes",
        severity: "error",
      });
    }
  };

  const handleAddTest = () => {
    if (newTest.trim()) {
      setFormData({
        ...formData,
        tests: [...formData.tests, newTest.trim()],
      });
      setNewTest("");
    }
  };

  const handleRemoveTest = (index: number) => {
    setFormData({
      ...formData,
      tests: formData.tests.filter((_: string, i: number) => i !== index),
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4">Health Risk Management</Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSaveChanges}
            color="secondary"
          >
            Save Changes
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
          >
            Add Risk
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {risks?.map((risk: HealthRisk) => (
          <Grid item xs={12} sm={6} md={4} key={risk._id}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              {/* <CardMedia
                component="img"
                height="200"
                alt={risk.categoryName}
              /> */}
              <CardContent sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography gutterBottom variant="h6" component="div">
                    {risk.categoryName}
                  </Typography>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => handleOpenDialog(risk)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDelete(risk._id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {risk.description}
                </Typography>
                <Box>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Included Tests:
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {risk?.tests?.map((test: any, index: number) => {
                      return (
                        <Chip
                          key={index}
                          label={test.name}
                          size="small"
                          sx={{ mb: 1 }}
                        />
                      );
                    })}
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingRisk ? "Edit Health Risk" : "Add New Health Risk"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="health Risk Name"
              fullWidth
              required
              value={formData.categoryName}
              onChange={(e) =>
                setFormData({ ...formData, categoryName: e.target.value })
              }
            />
            <TextField
              label="Description"
              fullWidth
              required
              multiline
              rows={3}
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Included Tests
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>Select Test</InputLabel>
                  <Select
                    value={newTest}
                    onChange={(e) => setNewTest(e.target.value)}
                    label="Select Test"
                  >
                    {test.map((t: any) => (
                      <MenuItem key={t._id} value={t._id}>
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button variant="outlined" onClick={handleAddTest}>
                  Add
                </Button>
              </Box>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {formData.tests.map((test: any, index: any) => (
                  <Chip
                    key={index}
                    label={test.name}
                    onDelete={() => handleRemoveTest(index)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            {editingRisk ? "Update" : "Add"} Risk
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default HealthRiskManager;
