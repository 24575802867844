import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";

interface Order {
  _id: string;
  createdAt: string;
  address: string;
  contact: string;
  items: any;
  totalAmount: number;
  status: string;
  isRead?: boolean;
}

interface Props {
  orderData?: Order[]; // Allow undefined to handle loading
}

const PatientOrder: React.FC<Props> = ({ orderData }) => {
  // Determine the status color for the Chip component
  const getStatusColor = (status: string) => {
    switch (status) {
      case "called":
        return "primary"; // Blue
      case "follow-up":
        return "success"; // Green
      case "closed":
        return "error"; // Red
      case "completed":
        return "secondary"; // Purple/Gray (MUI's secondary color)
      default:
        return "warning"; // Default fallback (e.g., yellow)
    }
  };
  

  // Format date to a readable string
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString("en-US", {
      dateStyle: "medium",
      timeStyle: "short",
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <TableContainer component={Paper}>
        {/* Show Loading State */}
        {!orderData ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
            <CircularProgress />
          </Box>
        ) : orderData.length === 0 ? (
          <Typography sx={{ p: 3, textAlign: "center" }}>
            No orders available.
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Items</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderData.map((order: any) => (
                <TableRow key={order._id}>
                  <TableCell>{formatDate(order.createdAt)}</TableCell>
                  <TableCell>{order.contact}</TableCell>
                  <TableCell>
                    {order.items.map((item: any) => (
                      <div key={item.id}>{item.name}</div>
                    ))}
                  </TableCell>
                  <TableCell>{order.totalAmount}</TableCell>
                  <TableCell>
                    <Chip
                      label={order.status}
                      color={getStatusColor(order.status)}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};

export default PatientOrder;
