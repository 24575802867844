import React, { createContext, useContext, useState, useEffect } from 'react';
 
interface AboutContent {
  content: string;
  lastUpdated: string;
}
 
interface AboutContextType {
  aboutContent: AboutContent;
  updateAboutContent: (content: string) => void;
}
 
const AboutContext = createContext<AboutContextType | undefined>(undefined);
 
const defaultAboutContent = {
  content: `
  <h1 style="text:center">About Us</h1>
  <h2 style="font-weight: bold; color: #000;">Our Vision</h2>
  <p>
    To be the most trusted and innovative healthcare diagnostics provider, empowering individuals to take charge of their health by delivering accurate, timely, and affordable medical testing services while fostering a healthier and happier community.
  </p>
  <div style="margin-top: 20px;"></div>
  <h2 style="font-weight: bold; color: #000;">Our Mission</h2>
  <p>At PS Healthcare, we are dedicated to:</p>
  <ul>
    <li>Providing unparalleled diagnostic services with a focus on precision and care.</li>
    <li>Ensuring every test result is delivered with accuracy and reliability.</li>
    <li>Expanding accessibility to healthcare diagnostics for underserved populations.</li>
    <li>Upholding the highest ethical standards and patient care practices.</li>
    <li>Leveraging cutting-edge technology to continually improve our services.</li>
  </ul>
  <div style="margin-top: 20px;"></div>
  <h2 style="font-weight: bold; color: #000;">Our Values</h2>
  <ul>
    <li><span style="font-weight: bold; color: #000;">Excellence in Service:</span> Striving for perfection in every diagnostic experience.</li>
    <li><span style="font-weight: bold; color: #000;">Patient-Centric Care: </span>Keeping the well-being of our patients at the core of everything we do.</li>
    <li><span style="font-weight: bold; color: #000;">Integrity and Trust:</span>Building strong, transparent relationships with our patients and partners.</li>
    <li><span style="font-weight: bold; color: #000;">Innovation in Healthcare: </span>Adopting the latest advancements to ensure the best outcomes.</li>
    <li><span style="font-weight: bold; color: #000;">Professional Ethics: </span>Committing to ethical practices in every aspect of our work.</li>
    <li><span style="font-weight: bold; color: #000;">Empathy and Compassion:</span>Providing care with a human touch to ensure comfort and satisfaction.</li>
  </ul>
  <div style="margin-top: 20px;"></div>
  <h2 style="font-weight: bold; color: #000;">Our Services</h2>
  <p>We offer a comprehensive range of diagnostic solutions tailored to meet your needs:</p>
  <ul>
     <li>Routine Blood Test</li>
     <li>Advanced Diagnostic Tests (e.g., genetic testing, molecular diagnostics)</li>
     <li>Preventive Health Checkups and Wellness Packages</li>
     <li>Home Collection Services for added convenience</li>
     <li>Online Reports with secure and easy access</li>
     <li>Expert Consultation with healthcare professionals for guidance</li>
     <li>Specialized Diagnostic Panels (e.g., diabetes, cardiac, and thyroid care)</li>
     <li>Corporate Health Checkups and Screenings for employee well-being</li>
     <li>Radiology and Imaging Services (e.g., X-rays, ultrasounds)</li>
  </ul>
  <div style="margin-top: 20px;"></div>
  <h2 style="font-weight: bold; color: #000;">Our Team</h2>
  <p>Our dedicated team of healthcare professionals is at the heart of our success:</p>
  <ul>
     <li>Experienced Pathologists with expertise in advanced diagnostics</li>
     <li>Highly Skilled Laboratory Technicians ensuring precision in every test</li>
     <li>Compassionate and Professional Phlebotomists for a painless experience</li>
     <li>Continuous Training for all staff to stay updated with advancements</li>
     <li>Accreditation from Recognized Healthcare Authorities to guarantee trust</li>
  </ul>
  <div style="margin-top: 20px;"></div>
  <h2 style="font-weight: bold; color: #000;">Quality Assurance</h2>
  <p>We maintain uncompromising standards of quality through:</p>
  <ul>
   <li>State-of-the-art Equipment using the latest technology</li>
   <li>Stringent Regular Quality Checks to ensure reliability and precision</li>
   <li> Standardized Procedures aligned with global best practices</li>
   <li>Continuous Training for all staff to stay updated with advancements</li>
   <li>Accreditation from Recognized Healthcare Authorities to guarantee trust</li>
  </ul>
  <div style="margin-top: 20px;"></div>
  <h2 style="font-weight: bold; color: #000;">Why Choose Us</h2>
  <p>We prioritize your health with:<p>
  <ul>
  <li>Accurate Results you can rely on for critical decisions</li>
  <li>Quick Turnaround Time to meet your urgent needs</li>
  <li>Affordable Rates ensuring accessibility for all</li>
  <li>Convenient Home Collection Service for hassle-free testing</li>
  <li>Online Report Access anytime, anywhere</li>
  <li>Expert Consultation to interpret results and guide your next steps</li>
  <li>Personalized Care with tailored health packages for individuals and families</li>
  <li>24/7 Support ensuring assistance whenever you need it</li>
  <li>Commitment to Innovation to bring you the best diagnostic solutions</li>
  </ul>
  `,
  lastUpdated: new Date().toISOString(),
};
 
export const AboutProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [aboutContent, setAboutContent] = useState<AboutContent>(defaultAboutContent);
 
  useEffect(() => {
    const savedContent = localStorage.getItem('aboutContent');
    if (savedContent) {
      try {
        setAboutContent(JSON.parse(savedContent));
      } catch (error) {
        console.error('Error parsing about content:', error);
        setAboutContent(defaultAboutContent);
      }
    }
  }, []);
 
  const updateAboutContent = (content: string) => {
    const updatedContent = {
      content,
      lastUpdated: new Date().toISOString(),
    };
    localStorage.setItem('aboutContent', JSON.stringify(updatedContent));
    setAboutContent(updatedContent);
  };
 
  return (
    <AboutContext.Provider value={{ aboutContent, updateAboutContent }}>
      {children}
    </AboutContext.Provider>
  );
};
 
export const useAbout = () => {
  const context = useContext(AboutContext);
  if (context === undefined) {
    throw new Error('useAbout must be used within an AboutProvider');
  }
  return context;
};