import React, { useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Chip,
} from '@mui/material';
import { useAppointment } from '../../context/AppointmentContext';
import { useDoctor } from '../../context/DoctorContext';

const AppointmentManager: React.FC = () => {
  const { appointments, updateAppointmentStatus } = useAppointment();
  const { getDoctorById } = useDoctor();


  const getStatusColor = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'success';
      case 'cancelled':
        return 'error';
      default:
        return 'warning';
    }
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Appointment Requests
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Patient Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Doctor</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments.map((appointment) => {
              const doctorId = appointment?.doctor._id;
              const doctor = getDoctorById(doctorId);
              // console.log("doctor data : ",appointment);
              return (
                <TableRow 
                  key={appointment._id}
                  sx={{ 
                    backgroundColor: !appointment.isRead ? 'action.hover' : 'inherit',
                  }}
                >
                  <TableCell>{formatDate(appointment.createdAt)}</TableCell>
                  <TableCell>{appointment.patient.fullName}</TableCell>
                  <TableCell>{appointment.patient.mobileNumber}</TableCell>
                  <TableCell>{doctor?.name || 'Unknown Doctor'}</TableCell>
                  <TableCell>{appointment.reason}</TableCell>
                  <TableCell>
                    <Chip
                      label={appointment.status}
                      color={getStatusColor(appointment.status)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {appointment.status === 'pending' && (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                          size="small"
                          variant="contained"
                          color="success"
                          onClick={() => updateAppointmentStatus(appointment._id, 'confirmed')}
                        >
                          Confirm
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          color="error"
                          onClick={() => updateAppointmentStatus(appointment._id, 'cancelled')}
                        >
                          Cancel
                        </Button>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AppointmentManager;
