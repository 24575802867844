import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
interface Banner {
  _id: string;
  imgUrl: string;
  title: string;
  description?: string;
  link?: string;
  order: number;
}

interface BannerContextType {
  banners: Banner[];
  addBanner: (banner: Omit<Banner, '_id'>) => void;
  updateBanner: (id: string, banner: Partial<Banner>) => void;
  deleteBanner: (id: string) => void;
  reorderBanners: (bannerId: string, newOrder: number) => void;
  saveBanners: () => Promise<void>;
  loading: boolean;
}

const API_URL = "https://fapi.pshealth.in/pos-health/api/banner";
const BannerContext = createContext<BannerContextType | undefined>(undefined);

export const BannerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [loading, setLoading] = useState(true);
  const [cookie] = useCookies(["adminToken"]);

  useEffect(() => {
    // Load banners from localStorage on mount
    const loadBanners = async () => {
      await fetch(`${API_URL}/get-all`,)
      .then((response)=>{return response.json()})
      .then((data)=>{
        setBanners(data.data);
        // console.log("banner data : ",data.data);
      });
    };
    loadBanners();
  }, []);

   // 🔵 Add a new banner
   const addBanner = async (banner: Omit<Banner, '_id'>) => {
    try {
      const response = await fetch(`${API_URL}/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.adminToken}`,
        },
        body: JSON.stringify(banner),
      });
      const data = await response.json();
      if (data.success) {
        setBanners(prev => [...prev, data.data]);
      } else {
        console.error("Failed to add banner:", data.message);
      }
    } catch (error) {
      console.error("Error adding banner:", error);
    }
  };

  // 🟠 Update an existing banner
  const updateBanner = async (id: string, updatedFields: Partial<Banner>) => {
    try {
      const response = await fetch(`${API_URL}/update/${id}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
        },
        body: JSON.stringify(updatedFields),
      });
      const data = await response.json();
      if (data.success) {
        setBanners((prev = []) => prev.map(b => (b._id === id ? { ...b, ...updatedFields } : b)));
      } else {
        console.error("Failed to update banner:", data.message);
      }
    } catch (error) {
      console.error("Error updating banner:", error);
    }
  };

   // 🔴 Delete a banner
   const deleteBanner = async (id: string) => {
    try {
      const response = await fetch(`${API_URL}/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookie.adminToken}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        setBanners(prev => prev.filter(banner => banner._id !== id));
      } else {
        console.error("Failed to delete banner:", data.message);
      }
    } catch (error) {
      console.error("Error deleting banner:", error);
    }
  };

  const reorderBanners = (bannerId: string, newOrder: number) => {
    setBanners(prev => {
      const banner = prev.find(b => b._id === bannerId);
      if (!banner) return prev;
  
      let updatedBanners = prev
        .filter(b => b._id !== bannerId)
        .sort((a, b) => a.order - b.order); // Ensure sorted before inserting
  
      updatedBanners.splice(newOrder, 0, banner); // Insert at new position
  
      const reordered = updatedBanners.map((b, index) => ({ ...b, order: index })); // Update order numbers
  
      localStorage.setItem("banners", JSON.stringify(reordered)); // Save updated order
  
      return reordered;
    });
  };
  

  const saveBanners = async () => {
    setLoading(true);
    try {
      localStorage.setItem('banners', JSON.stringify(banners));
    } catch (error) {
      console.error('Error saving banners:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BannerContext.Provider
      value={{
        banners,
        addBanner,
        updateBanner,
        deleteBanner,
        reorderBanners,
        saveBanners,
        loading
      }}
    >
      {children}
    </BannerContext.Provider>
  );
};

export const useBanner = () => {
  const context = useContext(BannerContext);
  if (context === undefined) {
    throw new Error('useBanner must be used within a BannerProvider');
  }
  return context;
};
