import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, CircularProgress, Box, Typography, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';
import { useNotification } from '../../context/NotificationContext';

interface SignupDialogProps {
  open: boolean;
  onClose: () => void;
  openLoginDialog: () => void;
}

const Signup: React.FC<SignupDialogProps> = ({ open, onClose, openLoginDialog }) => {
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const { showNotification } = useNotification();

  const handleSignup = async () => {
    if (!name || !email || !password || !phone || !age || !gender) {
      showNotification('Please fill all the required fields', 'error');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('https://fapi.pshealth.in/pos-health/api/patient/signup', {
        name,
        email,
        password,
        phone,
        age,
        gender,
      });
      if (response.status === 200) {
        showNotification('Sign Up successful! OTP sent to your phone.', 'success');
        setOtpSent(true);
      } else {
        showNotification('Something went wrong. Please try again later.', 'error');
      }
    } catch (error) {
      showNotification('Failed to sign up. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp || !email) {
      showNotification('Please enter the OTP and ensure your email is filled.', 'error');
      return;
    }
    setLoading(true);
    try {
      console.log("email : ",email);
      const response = await axios.post('https://fapi.pshealth.in/pos-health/api/patient/verify-otp', {
        email,
        otp,
      });
      if (response.status === 201) {
        showNotification('OTP verified successfully! Redirecting to login.', 'success');
        onClose();
        openLoginDialog();
      } else {
        showNotification('Invalid OTP. Please try again.', 'error');
      }
    } catch (error) {
      showNotification('Failed to verify OTP. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth PaperProps={{ sx: { borderRadius: 2, p: 2 } }}>
      <DialogTitle sx={{ pb: 1 }}>{otpSent ? 'Verify OTP' : 'Sign Up'}</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {otpSent ? 'Please enter the OTP sent to your phone.' : 'Please fill in the details to create an account.'}
        </Typography>
        {otpSent ? (
          <TextField
            fullWidth
            label="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            disabled={loading}
          />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField fullWidth label="Name" value={name} onChange={(e) => setName(e.target.value)} disabled={loading} />
            <TextField fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} type="email" disabled={loading} />
            <TextField fullWidth label="Password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" disabled={loading} />
            <TextField fullWidth label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" disabled={loading} />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField fullWidth label="Age" value={age} onChange={(e) => setAge(e.target.value)} type="number" disabled={loading} />
              <FormControl fullWidth disabled={loading}>
                <InputLabel>Gender</InputLabel>
                <Select value={gender} onChange={(e) => setGender(e.target.value)} label="Gender">
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Transgender">Transgender</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button onClick={otpSent ? handleVerifyOtp : handleSignup} variant="contained" disabled={loading} sx={{ minWidth: 100 }}>
          {loading ? <CircularProgress size={24} /> : otpSent ? 'Verify OTP' : 'Sign Up'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Signup;
