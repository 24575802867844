import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { useNotification } from "../context/NotificationContext";
import { useHomeCollection } from "../context/HomeCollectionContext";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

interface HomeCollectionFormProps {
  onSubmitSuccess?: () => void;
  onClose?: () => void;
}

interface FormData {
  name: string;
  phone: string;
  address: string;
  city: string;
  pincode: string;
  preferredDate: string;
  preferredTime: string;
}

interface FormErrors {
  name?: string;
  phone?: string;
  address?: string;
  city?: string;
  pincode?: string;
  preferredDate?: string;
  preferredTime?: string;
}

const initialFormData: FormData = {
  name: "",
  phone: "",
  address: "",
  city: "",
  pincode: "",
  preferredDate: "",
  preferredTime: "",
};

const timeSlots = [
  "07:00 AM - 09:00 AM",
  "09:00 AM - 11:00 AM",
  "11:00 AM - 01:00 PM",
  "01:00 PM - 03:00 PM",
  "03:00 PM - 05:00 PM",
  "05:00 PM - 07:00 PM",
];

const HomeCollectionForm: React.FC<HomeCollectionFormProps> = ({
  onSubmitSuccess,
  onClose,
}) => {
  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [errors, setErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const { addRequest } = useHomeCollection();
  const [cookies] = useCookies(["userToken"]);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (!formData.preferredDate) {
      newErrors.preferredDate = "Preferred date is required";
    }

    if (!formData.preferredTime) {
      newErrors.preferredTime = "Preferred time is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (validateForm()) {
        const token = cookies.userToken;
        if (!token) {
          console.error("User not authenticated.");
          navigate("/login");
          return;
        }
        // Add the request to the context
        addRequest(
          {
            preferredDate: formData.preferredDate,
            preferredTime: formData.preferredTime,
          },
          token
        );

        showNotification(
          "Home collection request submitted successfully!",
          "success"
        );
        setFormData(initialFormData);
        if (onSubmitSuccess) onSubmitSuccess();
        if (onClose) onClose();
      }
    } catch (error) {
      console.error("Error submitting home collection request:", error);
      showNotification("Failed to submit request. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange =
    (field: keyof FormData) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;

      // Handle phone number input
      if (field === "phone") {
        // Only allow digits and limit to 10 characters
        const sanitizedValue = value.replace(/\D/g, "").slice(0, 10);
        setFormData({ ...formData, [field]: sanitizedValue });
        if (errors[field]) {
          setErrors({ ...errors, [field]: undefined });
        }
      } else {
        setFormData({ ...formData, [field]: value });
        if (errors[field]) {
          setErrors({ ...errors, [field]: undefined });
        }
      }
    };

  const handleTimeChange = (e: SelectChangeEvent<string>) => {
    setFormData({ ...formData, preferredTime: e.target.value });
    if (errors.preferredTime) {
      setErrors({ ...errors, preferredTime: undefined });
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, preferredDate: e.target.value });
    if (errors.preferredDate) {
      setErrors({ ...errors, preferredDate: undefined });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ mb: 1, color: "#666" }}>
            Please fill in your details for home collection
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            size="small"
            fullWidth
            label="Preferred Date"
            type="date"
            value={formData.preferredDate}
            onChange={handleDateChange}
            error={!!errors.preferredDate}
            helperText={errors.preferredDate}
            required
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: new Date().toISOString().split("T")[0],
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            error={!!errors.preferredTime}
            required
            size="small"
          >
            <InputLabel>Preferred Time</InputLabel>
            <Select
              value={formData.preferredTime}
              label="Preferred Time"
              onChange={handleTimeChange}
            >
              {timeSlots.map((slot) => (
                <MenuItem key={slot} value={slot}>
                  {slot}
                </MenuItem>
              ))}
            </Select>
            {errors.preferredTime && (
              <FormHelperText>{errors.preferredTime}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{
              mt: { xs: 0.5, sm: 1 },
              py: 1,
              bgcolor: "#3F1E43",
              "&:hover": {
                bgcolor: "#2E1632",
              },
            }}
          >
            {loading ? "Submitting..." : "Schedule Home Collection"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeCollectionForm;
