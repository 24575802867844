import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Input,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { useDoctor, Doctor } from "../../context/DoctorContext";
import { useNotification } from "../../context/NotificationContext";
import { useImages } from "../../context/ImageContext";

interface DoctorFormData {
  name: string;
  specialty: string;
  qualification: string;
  experience: number;
  imgUrl: string | any;
  availabilityTime: string[];
  availabilityDays: string[];
  consultationFee: number;
  about: string | any;
  phone: string | any;
  email: string | any;
}

const initialFormData: DoctorFormData = {
  name: "",
  specialty: "",
  qualification: "",
  experience: 0,
  imgUrl: "",
  availabilityTime: [],
  availabilityDays: [],
  consultationFee: 0,
  about: "",
  phone: "",
  email: "",
};

const specialties = [
  "Cardiology",
  "Dermatology",
  "Endocrinology",
  "Gastroenterology",
  "Neurology",
  "Oncology",
  "Orthopedics",
  "Pediatrics",
  "Psychiatry",
  "Urology",
];

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const DoctorManager: React.FC = () => {
  const { doctors, addDoctor, updateDoctor, deleteDoctor } = useDoctor();
  const { showNotification } = useNotification();
  const { uploadImage } = useImages();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<DoctorFormData>(initialFormData);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setUploading(true);
      const uploadedUrl = await uploadImage(file);
      setFormData((prev) => ({ ...prev, image: uploadedUrl }));
      showNotification("Image uploaded successfully", "success");
    } catch (error) {
      showNotification("Failed to upload image", "error");
    } finally {
      setUploading(false);
    }
  };

  const handleOpen = (doctor?: Doctor) => {
    if (doctor) {
      setFormData({
        name: doctor.name,
        specialty: doctor.specialty,
        qualification: doctor.qualification,
        experience: doctor.experience,
        imgUrl: doctor.imgUrl,
        availabilityTime: doctor.availabilityTime,
        availabilityDays: doctor.availabilityDays,
        consultationFee: doctor.consultationFee,
        about: doctor.about,
        phone: doctor.phone,
        email: doctor.email,
      });
      setEditingId(doctor._id);
    } else {
      setFormData(initialFormData);
      setEditingId(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(initialFormData);
    setEditingId(null);
  };

  const handleSubmit = () => {
    if (editingId) {
      updateDoctor(editingId, formData);
      showNotification("Doctor updated successfully", "success");
    } else {
      addDoctor(formData);
      showNotification("Doctor added successfully", "success");
    }
    handleClose();
  };

  const handleDelete = (id: string) => {
    if (window.confirm("Are you sure you want to delete this doctor?")) {
      deleteDoctor(id);
      showNotification("Doctor deleted successfully", "success");
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4" sx={{ color: "primary.main" }}>
          Doctor Manager
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
          sx={{
            background: `linear-gradient(45deg, primary.main 30%, primary.light 90%)`,
            color: "white",
            boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
          }}
        >
          Add New Doctor
        </Button>
      </Box>

      <Grid container spacing={3}>
        {doctors.map((doctor: any) => (
          <Grid item xs={12} sm={6} md={4} key={doctor.id}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <CardMedia
                component="img"
                height="200"
                image={doctor.imgUrl || "/placeholder-doctor.jpg"}
                alt={doctor.name}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h6" component="h2">
                  {doctor.name}
                </Typography>
                <Chip
                  label={doctor.specialty}
                  color="primary"
                  size="small"
                  sx={{ mb: 1 }}
                />
                <Typography variant="body2" color="text.secondary">
                  Qualification: {doctor.qualification}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Experience: {doctor.experience} years
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Phone: {doctor.phone}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Email: {doctor.email}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Available Days: {doctor.availabilityDays.join(", ")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Available Times: {doctor.availabilityTime.join(", ")}
                </Typography>
                <Typography variant="body2" color="primary">
                  Consultation Fee: ₹{doctor.consultationFee}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <IconButton onClick={() => handleOpen(doctor)} color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDelete(doctor._id)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingId ? "Edit Doctor" : "Add New Doctor"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ my: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Name"
              fullWidth
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
            <TextField
              label="Phone"
              fullWidth
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
            />
            <TextField
              label="Email"
              fullWidth
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
            <FormControl fullWidth>
              <InputLabel>Specialty</InputLabel>
              <Select
                value={formData.specialty}
                onChange={(e) =>
                  setFormData({ ...formData, specialty: e.target.value })
                }
                label="Specialty"
              >
                {specialties.map((specialty) => (
                  <MenuItem key={specialty} value={specialty}>
                    {specialty}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Qualification"
              fullWidth
              value={formData.qualification}
              onChange={(e) =>
                setFormData({ ...formData, qualification: e.target.value })
              }
            />
            <TextField
              label="Experience (years)"
              type="number"
              fullWidth
              value={formData.experience}
              onChange={(e) =>
                setFormData({ ...formData, experience: Number(e.target.value) })
              }
            />
            <TextField
              label="Consultation Fee"
              type="number"
              fullWidth
              value={formData.consultationFee}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  consultationFee: Number(e.target.value),
                })
              }
            />
            <TextField
              label="About"
              fullWidth
              multiline
              rows={4}
              value={formData.about}
              onChange={(e) =>
                setFormData({ ...formData, about: e.target.value })
              }
            />

            {/* Update availability days with a dropdown */}
            <FormControl fullWidth>
              <InputLabel>Availability Days</InputLabel>
              <Select
                multiple
                value={formData.availabilityDays}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    availabilityDays: e.target.value as string[],
                  })
                }
                label="Availability Days"
              >
                {daysOfWeek.map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Availability Times (comma separated)"
              fullWidth
              value={formData.availabilityTime?.join(", ") || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  availabilityTime: e.target.value
                    .split(",")
                    .map((time) => time.trim()),
                })
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {editingId ? "Update" : "Add"} Doctor
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DoctorManager;
