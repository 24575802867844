import React, { createContext, useContext, useState, useEffect } from "react";

export interface Patient {
  _id: string;
  email: string;
  password: string;
  fullName: string;
  mobileNumber: string;
  address: string;
  city: string;
  pinCode: string;
  age?: number;
  gender:string;
  medicalHistory: string[];
  role: string;
  imgUrl?: string;
}

interface PatientContextType {
  patients: Patient[];
  addPatient: (patient: Omit<Patient, "_id">) => Promise<void>;
  updatePatient: (id: string, patient: Partial<Patient>) => Promise<any>;
  deletePatient: (id: string) => Promise<void>;
  getPatientById: (id: string) => Patient | undefined;
  getAppointmentInfo: (id: string) => Promise<any>;
  getHomeCollectionInfo: (id: string) => Promise<any>;
  getOrderInfo: (id: string) => Promise<any>;
  fetchPatientData: (id: string) => Promise<any>;
  authenticatePatient: (email: string, password: string) => Promise<boolean>;
}

const PatientContext = createContext<PatientContextType | undefined>(undefined);

export const PatientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [patients, setPatients] = useState<Patient[]>([]);


  const fetchPatientData = async (token:any) => {
    try {
  
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/patient/get-profile-info",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setPatients(Array.isArray(data.patients) ? data.patients : []);
      return data;
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  const addPatient = async (patientData: Omit<Patient, "_id">) => {
    try {
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/patient/add-new",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(patientData),
        }
      );
      const newPatient = await response.json();
      setPatients((prev) => [...prev, newPatient]);
    } catch (error) {
      console.error("Error adding patient:", error);
    }
  };

  const updatePatient = async (id: string, patientData: Partial<Patient>) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/patient/update-info/${id}`,
        {
          method: "PATCH", // Corrected from PUT to PATCH
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(patientData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update patient information");
      }

      setPatients((prev = []) =>
        prev.map((patient) =>
          patient._id === id ? { ...patient, ...patientData } : patient
        )
      );      
      
      return response;
    } catch (error) {
      console.error("Error updating patient:", error);
    }
  };

  const deletePatient = async (id: string) => {
    try {
      await fetch(`https://fapi.pshealth.in/pos-health/api/patient/delete/${id}`, {
        method: "DELETE",
      });
      setPatients((prev) => prev.filter((patient) => patient._id !== id));
    } catch (error) {
      console.error("Error deleting patient:", error);
    }
  };

  const getPatientById = (id: string) => {
    return patients.find((patient) => patient._id === id);
  };

  const authenticatePatient = async (email: string, password: string) => {
    try {
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/patient/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password }),
        }
      );
      const data = await response.json();
      return data.success;
    } catch (error) {
      console.error("Error authenticating patient:", error);
      return false;
    }
  };

  const getAppointmentInfo = async (token: any) => {
    try {
      // console.log("token : ",token)

      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/patient/get-appointment-info`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      // console.log("user appointment data : ",data);
      return data;
    } catch (error) {}
  };

  const getHomeCollectionInfo = async (token: any) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/patient/get-homeCollection-info`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {}
  };

  const getOrderInfo = async (token: any) => {
   
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/order/get-by-user`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("order patient context : ",data);
      return data;
    } catch (error:any) {
      console.log("Something went wrong while fetching patient order request data",error.message);
    }
  };

  return (
    <PatientContext.Provider
      value={{
        patients,
        addPatient,
        updatePatient,
        deletePatient,
        getPatientById,
        authenticatePatient,
        getAppointmentInfo,
        getHomeCollectionInfo,
        getOrderInfo,
        fetchPatientData
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error("usePatient must be used within a PatientProvider");
  }
  return context;
};
