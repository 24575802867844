import React, { createContext, useContext, useState, useEffect } from 'react';
 
interface PrivacyPolicy {
  content: string;
  lastUpdated: string;
}
 
interface PrivacyPolicyContextType {
  privacyPolicy: PrivacyPolicy;
  updatePrivacyPolicy: (content: string) => void;
}
 
const PrivacyPolicyContext = createContext<PrivacyPolicyContextType | undefined>(undefined);
 
const defaultPrivacyPolicy = {
  content: `
    <h4 style="font-weight: bold;">Introduction</h4>
    <p>Welcome to PS Health. Your privacy is important to us, and we are committed to protecting your personal information.
    This privacy policy explains how we collect, use, store, and safeguard your data when you use our services.</p>
    <div style="margin-top: 20px;"></div>
    <h4 style="font-weight: bold;">Information We Collect</h4>
    <p>We may collect the following types of personal information:</p>
    <li><span style="font-weight: bold; color: #000;">Personal Identification Information: </span>Name, email address, phone number, and other contact details.</li>
    <li><span style="font-weight: bold; color: #000;">Medical Information: </span>Test requests, medical history, and test results.</li>
    <li><span style="font-weight: bold; color: #000;">Payment Information: </span> Billing details, payment methods, and transaction records.</li>
    <li><span style="font-weight: bold; color: #000;">Technical Data: </span>IP addresses, browser type, and other device-related information when you access our website.</li>
    <div style="margin-top: 20px;"></div>
    <h4 style="font-weight: bold;">How We Use Your Information</h4>
    <p>We use your personal information for the following purposes:</p>
    <li>To provide accurate and reliable medical testing services.</li>
    <li>To communicate your test results and other relevant health-related information.</li>
    <li>To process payments and manage billing.</li>
    <li>To improve our services and enhance the patient experience.</li>
    <li>To comply with legal and regulatory requirements.</li>
    <div style="margin-top: 20px;"></div>
    <h4 style="font-weight: bold;">Data Protection & Security</h4>
    <p>We take the security of your personal data seriously and implement appropriate technical and organizational measures to:</p>
    <li>Prevent unauthorized access, disclosure, or alteration of your data.</li>
    <li>Ensure encrypted storage and secure transmission of sensitive information.</li>
    <li>Regularly update our security protocols to maintain compliance with industry standards.</li>
    <div style="margin-top: 20px;"></div>
    <h4 style="font-weight: bold;">Sharing of Information</h4>
    <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your data with:</p>
    <li><span style="font-weight: bold; color: #000;">Healthcare providers: </span>To ensure proper diagnosis and treatment.</li>
    <li><span style="font-weight: bold; color: #000;">Payment processors: </span> To facilitate secure transactions.</li>
    <li><span style="font-weight: bold; color: #000;">Regulatory authorities: </span> When required by law or to protect public health.</li>
    <div style="margin-top: 20px;"></div>
    <h4 style="font-weight: bold;">Your Rights & Choices</h4>
    <p>You have the right to:</p>
    <li>Access, update, or correct your personal information.</li>
    <li>Request deletion of your data, subject to legal and regulatory obligations.</li>
    <li>Withdraw consent for certain data processing activities.</li>
    <div style="margin-top: 20px;"></div>
    <h4 style="font-weight: bold;">Changes to This Policy</h4>
    <p>We may update this privacy policy from time to time. Any changes will be posted on our website, and we encourage you to review this policy periodically.</p>
    <div style="margin-top: 20px;"></div>
    <h4 style="font-weight: bold;">Contact Us</h4>
    <p>If you have any questions or concerns about this privacy policy or how your information is handled, please contact us at: </p>
    <p><span style="font-weight: bold; color: #000;">Email: </span> contact@pshealth.com</p>
    <p><span style="font-weight: bold; color: #000;">Contact: </span>+91 1234567890</p>
    </ul>
  `,
  lastUpdated: new Date().toISOString(),
};
 
export const PrivacyPolicyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [privacyPolicy, setPrivacyPolicy] = useState<PrivacyPolicy>(defaultPrivacyPolicy);
 
  useEffect(() => {
    const savedPolicy = localStorage.getItem('privacyPolicy');
    if (savedPolicy) {
      try {
        setPrivacyPolicy(JSON.parse(savedPolicy));
      } catch (error) {
        console.error('Error parsing privacy policy:', error);
        setPrivacyPolicy(defaultPrivacyPolicy);
      }
    }
  }, []);
 
  const updatePrivacyPolicy = (content: string) => {
    const updatedPolicy = {
      content,
      lastUpdated: new Date().toISOString(),
    };
    localStorage.setItem('privacyPolicy', JSON.stringify(updatedPolicy));
    setPrivacyPolicy(updatedPolicy);
  };
 
  return (
    <PrivacyPolicyContext.Provider value={{ privacyPolicy, updatePrivacyPolicy }}>
      {children}
    </PrivacyPolicyContext.Provider>
  );
};
 
export const usePrivacyPolicy = () => {
  const context = useContext(PrivacyPolicyContext);
  if (context === undefined) {
    throw new Error('usePrivacyPolicy must be used within a PrivacyPolicyProvider');
  }
  return context;
};