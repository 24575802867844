import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  CircularProgress,
} from '@mui/material';

interface Appointment {
  _id: string;
  createdAt: string;
  patient: any;
  contactNumber: string;
  doctor?: { name: string };
  reason: string;
  status: string;
  isRead?: boolean;
}

interface Props {
  appointmentData?: Appointment[]; // Allow undefined for loading state
}

const PatientAppointment: React.FC<Props> = ({ appointmentData }) => {
  // Determine the status color for the Chip component
  const getStatusColor = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'success';
      case 'cancelled':
        return 'error';
      default:
        return 'warning';
    }
  };

  console.log("appointment : ",appointmentData)

  // Format date to a readable string
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <TableContainer component={Paper}>
        {/* Show Loading State */}
        {!appointmentData ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : appointmentData.length === 0 ? (
          <Typography sx={{ p: 3, textAlign: 'center' }}>
            No Appointments Available.
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Doctor</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointmentData.map((appointment) => {
                const doctor = appointment?.doctor || { name: 'Unknown Doctor' };

                return (
                  <TableRow
                    key={appointment._id}
                    sx={{
                      backgroundColor: !appointment.isRead ? 'action.hover' : 'inherit',
                    }}
                  >
                    <TableCell>{formatDate(appointment.createdAt)}</TableCell>
                    <TableCell>{doctor.name}</TableCell>
                    <TableCell>{appointment.reason}</TableCell>
                    <TableCell>
                      <Chip
                        label={appointment.status}
                        color={getStatusColor(appointment.status)}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};

export default PatientAppointment;
