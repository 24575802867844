import React, { createContext, useContext, useState, useEffect } from "react";
import { HealthRisk } from "../data/healthRisk";
import { useCookies } from "react-cookie";

interface HealthRiskContextType {
  risks: HealthRisk[];
  addRisk: (risk: HealthRisk) => void;
  updateRisk: (id: string, risk: HealthRisk) => void;
  deleteRisk: (id: string) => void;
  saveChanges: () => Promise<void>;
}

const HealthRiskContext = createContext<HealthRiskContextType | undefined>(
  undefined
);

export const useHealthRisk = () => {
  const context = useContext(HealthRiskContext);
  if (!context) {
    throw new Error("useHealthRisk must be used within a HealthRiskProvider");
  }
  return context;
};

export const HealthRiskProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [risks, setRisks] = useState<HealthRisk[]>([]);
  const [cookie] = useCookies(["adminToken"]);


  useEffect(() => {
    const fetchRisks = async () => {
      try {
        const response = await fetch(
          "https://fapi.pshealth.in/pos-health/api/health-risk/get-all"
        );
        const data = await response.json();
        setRisks(data);

        // console.log("HealthRisk", data);
      } catch (error) {
        console.error("Error fetching health risks:", error);
      }
    };

    fetchRisks();
  }, []);

  const addRisk = async (risk: HealthRisk) => {
    try {
        // console.log("context risk : ",risk);
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/health-risk/add-new",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(risk),
        }
      );
      const newRisk = await response.json();
      console.log("new health risk : ",newRisk);
      setRisks((prev=[]) => [...prev, newRisk.healthRisk]);
    } catch (error) {
      console.error("Error adding health risk:", error);
    }
  };

  const updateRisk = async (id: any, updatedRisk: HealthRisk) => {
    try {
      await fetch(
        `https://fapi.pshealth.in/pos-health/api/health-risk/update/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(updatedRisk),
        }
      );
      setRisks((prev) =>
        prev.map((risk) => (risk._id === id ? updatedRisk : risk))
      );
    } catch (error) {
      console.error("Error updating health risk:", error);
    }
  };

  const deleteRisk = async (id: any) => {
    try {
      await fetch(
        `https://fapi.pshealth.in/pos-health/api/health-risk/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );
      setRisks((prev) => prev.filter((risk) => risk._id !== id));
    } catch (error) {
      console.error("Error deleting health risk:", error);
    }
  };

  const saveChanges = async () => {
    try {
      localStorage.setItem("healthRisks", JSON.stringify(risks));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <HealthRiskContext.Provider
      value={{
        risks,
        addRisk,
        updateRisk,
        deleteRisk,
        saveChanges,
      }}
    >
      {children}
    </HealthRiskContext.Provider>
  );
};
