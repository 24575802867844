import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  CircularProgress,
} from '@mui/material';

interface PatientHomeCollection {
  _id: string;
  createdAt: string;
  preferredTime: string;
  preferredDate: string;
  status: string;
  isRead?: boolean;
}

interface Props {
  homeCollectionData?: PatientHomeCollection[]; // Allow undefined for loading state
}

const PatientHomeCollection: React.FC<Props> = ({ homeCollectionData }) => {
  // Define status colors
  const getStatusColor = (status: string) => {
    const statusColors: Record<string, any> = {
      pending: 'warning',
      call_done: 'info',
      follow_up: 'secondary',
      deal_closed: 'success',
      not_interested: 'error',
    };
    return statusColors[status] || 'default';
  };

  // Format date and time
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', { dateStyle: 'medium' });
  };

  return (
    <Box sx={{ p: 3 }}>
      <TableContainer component={Paper}>
        {/* Show Loading State */}
        {!homeCollectionData ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : homeCollectionData.length === 0 ? (
          <Typography sx={{ p: 3, textAlign: 'center' }}>
            No Records Available.
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Request Date</TableCell>
                <TableCell>Preferred Date</TableCell>
                <TableCell>Preferred Time</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {homeCollectionData.map((record) => (
                <TableRow
                  key={record._id}
                  sx={{ backgroundColor: !record.isRead ? 'action.hover' : 'inherit' }}
                >
                  <TableCell>{formatDate(record.createdAt)}</TableCell>
                  <TableCell>{formatDate(record.preferredDate)}</TableCell>
                  <TableCell>{record.preferredTime}</TableCell>
                  <TableCell>
                    <Chip
                      label={record.status.replace('_', ' ')}
                      color={getStatusColor(record.status)}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};

export default PatientHomeCollection;
