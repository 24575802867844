import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";

interface BloodTest {
  _id: string;
  name: string;
  price: number;
  description: string;
  parameters: string[];
  preparation: string[];
  turnaroundTime: string;
  published: boolean;
}

// Define the context type
interface BloodTestContextType {
  tests: BloodTest[];
  addTest: (test: BloodTest) => void;
  updateTest: (test: BloodTest) => void;
  deleteTest: (id: string) => void;
  togglePublishBloodTest: (id: string) => void;
  openEditDialog: (test: BloodTest) => void;
  closeEditDialog: () => void;
  selectedTest: BloodTest | null;
  loading: boolean;
}

// Create the context
const BloodTestContext = createContext<BloodTestContextType | undefined>(
  undefined
);

export const BloodTestProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tests, setTests] = useState<BloodTest[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTest, setSelectedTest] = useState<BloodTest | null>(null); // To store the test being edited
  const [cookie] = useCookies(["adminToken"]);
  // Fetch all blood tests on load
  useEffect(() => {
    const fetchTests = async () => {
      try {
        const res = await axios.get(
          "https://fapi.pshealth.in/pos-health/api/blood-test/get-all",
          { withCredentials: true }
        );
        if (res.data && res.data.test) {
          setTests(res.data.test);
        } else {
          console.warn("No tests found in response:", res.data);
        }
      } catch (error: unknown) {
        console.error("Error fetching tests:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTests();
  }, []);
  const addTest = async (test: BloodTest) => {
    try {
      const newTest = {
        name: test.name,
        description: test.description,
        testPreparation: Array.isArray(test.preparation)
          ? test.preparation
          : [],
        parameterTested: Array.isArray(test.parameters) ? test.parameters : [],
        price: test.price,
        turnaroundTime: test.turnaroundTime,
      };

      const res = await fetch(
        "https://fapi.pshealth.in/pos-health/api/blood-test/add-new",
        {
          method:"POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(newTest),
        }
      );
      const result = await res.json();
      if (result.test) {
        setTests((prevTests=[]) => [...prevTests, result.test]);
      }
    } catch (err) {
      console.error("Failed to add the test:", err);
    }
  };


  const updateTest = async (updatedTest: BloodTest) => {
    try {
      console.log("inside update blood test!")
      const res = await fetch(
        `https://fapi.pshealth.in/pos-health/api/blood-test/update/${updatedTest._id}`,
        {
          method:"PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(updatedTest),
        }
      );
      const result = await res.json();

      if (res.status === 200) {
        setTests((prevTests) =>
          prevTests.map((test) =>
            test._id === updatedTest._id ? { ...test, ...result.data } : test
          )
        );
      }
    } catch (err) {
      console.error("Error updating test:", err);
    }
  };


  const deleteTest = async (id: string) => {
    try {
      await fetch(
        `https://fapi.pshealth.in/pos-health/api/blood-test/delete/${id}`,
        {
          method:"DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );

      setTests((prevTests) => prevTests.filter((test) => test._id !== id));
    } catch (err) {
      console.error("Error deleting test:", err);
    }
  };

  const togglePublishBloodTest = async (id: string) => {
    try {
      const res = await fetch(
        `https://fapi.pshealth.in/pos-health/api/blood-test/toggle-publish/${id}`,
        {
          method: 'PATCH',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );
      const result = await res.json();

      console.log("updating res : ",result)
      if (res.status === 200) {
        setTests((prevTests=[]) =>
          prevTests.map((test) =>
            test._id === id ? { ...test, published: result.updatedBloodTest.published } : test
          )
        );
      }
    } catch (err) {
      console.error("Error toggling publish status:", err);
    }
  };



  const openEditDialog = (test: BloodTest) => {
    setSelectedTest(test);
  };
  const closeEditDialog = () => {
    setSelectedTest(null);
  };

  return (
    <BloodTestContext.Provider
      value={{
        tests,
        addTest,
        updateTest,
        deleteTest,
        togglePublishBloodTest,
        openEditDialog,
        closeEditDialog,
        selectedTest,
        loading,
      }}
    >
      {children}
    </BloodTestContext.Provider>
  );
};
export const useBloodTest = () => {
  const context = useContext(BloodTestContext);
  if (context === undefined) {
    throw new Error("useBloodTest must be used within a BloodTestProvider");
  }
  return context;
};
