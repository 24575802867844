import React, { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export interface CartItem {
  _id?: any;
  item: any;
  itemType?: "Test" | "HealthPackage";
  quantity?: number;
  totalAmount?: number;
}

interface CartContextType {
  items: CartItem[];
  addItem: (item: CartItem) => void;
  removeItem: (id: string) => void;
  clearCart: () => void;
  totalAmount: number;
  totalItems: number;
  fetchCart: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [items, setItems] = useState<CartItem[]>([]);
  const [token, setToken] = useState<string | undefined>();
  const [cookie] = useCookies(["userToken"]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (cookie.userToken) {
      setToken(cookie.userToken);
      fetchCart();
    }
  }, [cookie.userToken]);

  useEffect(() => {
    if (token) {
      fetchCart();
    }
  }, [token]);


  const fetchCart = async () => {
    if (!token) return;
    try {
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/cart/get-cart-by-patient",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setItems(data.cart.items || []);
        setTotalAmount(data.cart.totalAmount || 0);
      }
    } catch (error) {
      console.error("Error fetching cart:", error);
    }
  };

  // Add Item to Cart
  const addItem = async (item: CartItem) => {
    try {
      // console.log("add to cart : ",item);
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/cart/add-to-cart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(item),
        }
      );

      const data = await response.json();
      if (data.status == 200) {
        await fetchCart();
      }
    } catch (error) {
      console.error("Error adding item:", error);
    }
  };

  // Remove Item from Cart
  const removeItem = async (id: string) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/cart/remove/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (data.status == 200) {
        await fetchCart();
      }
    } catch (error) {
      console.error("Error removing item:", error);
    }
  };

  // Clear Cart
  const clearCart = async () => {
    try {
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/cart/clear",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setItems([]);
      }
    } catch (error) {
      console.error("Error clearing cart:", error);
    }
  };
  // console.log("cart items : ",items);
  const totalItems = items.length;

  return (
    <CartContext.Provider
      value={{
        items,
        addItem,
        removeItem,
        clearCart,
        totalAmount,
        totalItems,
        fetchCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};
