import React, { createContext, useContext, useState, useEffect } from "react";
import { sendAdminNotification } from "../utils/emailService";
import { useCookies } from "react-cookie";

export interface CartRequest {
  id: string;
  patientName: string;
  address: string;
  mobile: string;
  items: Array<{
    id: string;
    name: string;
    price: number;
  }>;
  status: "pending" | "called" | "follow-up" | "closed" | "completed";
  createdAt: string;
  notes?: string;
  totalAmount: number;
}

interface CartRequestContextType {
  requests: CartRequest[];
  updateRequestStatus: (
    id: string,
    status: CartRequest["status"],
    notes?: string
  ) => void;
  addRequest: (request: Omit<CartRequest, "id" | "createdAt">) => void;
  getRequest: (id: string) => CartRequest | undefined;
  refreshRequests: () => void;
  deleteRequest: (id: string) => void;
  loading: boolean;
}

const CartRequestContext = createContext<CartRequestContextType | undefined>(
  undefined
);

export const useCartRequests = () => {
  const context = useContext(CartRequestContext);
  if (!context) {
    throw new Error(
      "useCartRequests must be used within a CartRequestProvider"
    );
  }
  return context;
};

export const CartRequestProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [requests, setRequests] = useState<CartRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const [cookie] = useCookies(["adminToken"]);
  const [token, setToken] = useState<string | undefined>();
  
  const SERVER_URL = "https://fapi.pshealth.in"; // Replace with your actual API URL

  useEffect(() => {
    if (cookie.adminToken) {
      setToken(cookie.adminToken);
    }
  }, [cookie.adminToken]);

  useEffect(() => {
    if (token) {
      fetchOrder();
    }
  }, [token]);

  const fetchOrder = async () => {
    if (!token) return;
    try {
      const response = await fetch(`${SERVER_URL}/pos-health/api/order/get-all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        setRequests(data.ordersData || []);
      } else {
        console.error("Failed to fetch orders:", data.message);
      }
    } catch (error) {
      console.error("Error fetching cart:", error);
    }
  };

  const updateRequestStatus = async (
    id: string,
    status: CartRequest["status"],
    notes?: string
  ) => {
    if (!token) return;
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/order/update-status/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ status, notes }),
        }
      );

      const data = await response.json();

      if (data.status==200) {
        setRequests((prevRequests) =>
          prevRequests.map((request) =>
            request.id === id ? { ...request, status, notes } : request
          )
        );
      } else {
        console.error("Failed to update status:", data.message);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const addRequest = (request: Omit<CartRequest, "id" | "createdAt">) => {
    const newRequest: CartRequest = {
      ...request,
      id: Date.now().toString(),
      createdAt: new Date().toISOString(),
    };
    setRequests((prev) => [...prev, newRequest]);

    // Send email notification
    sendAdminNotification({
      subject: "New Cart Request",
      message: "A new cart request has been received.",
      details: {
        "Patient Name": request.patientName,
        Mobile: request.mobile,
        Address: request.address,
        "Total Amount": `₹${request.totalAmount}`,
        Items: request.items
          .map((item) => `${item.name} (₹${item.price})`)
          .join("\n"),
      },
    }).catch((error) => {
      console.error("Failed to send email notification:", error);
    });
  };

  const getRequest = (id: string) => {
    return requests.find((request) => request.id === id);
  };

  const deleteRequest = async (id: string) => {
    if (!token) return;
    try {
      const response = await fetch(
        `${SERVER_URL}/pos-health/api/order/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      console.log("delete res : ",data);
      if (data.status==200) {
        setRequests((prev) => prev.filter((request) => request.id !== id));
      } else {
        console.error("Failed to delete request:", data.message);
      }
    } catch (error) {
      console.error("Error deleting request:", error);
    }
  };

  return (
    <CartRequestContext.Provider
      value={{
        requests,
        updateRequestStatus,
        addRequest,
        getRequest,
        refreshRequests: fetchOrder,
        deleteRequest,
        loading,
      }}
    >
      {children}
    </CartRequestContext.Provider>
  );
};
