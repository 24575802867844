import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddShoppingCart, Check, AccessTime, Science } from '@mui/icons-material';
import { useCart } from '../context/CartContext';
import { useNotification } from '../context/NotificationContext';
import { useBloodTest } from '../context/BloodTestContext';

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
}));

const BloodTestDetails: React.FC = () => {
  const { testId } = useParams<{ testId: string }>();
  const { addItem } = useCart();
  const { showNotification } = useNotification();
  const { tests, loading } = useBloodTest();

  const testData = tests.find((test) => test._id === testId && test.published);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress color="primary" />
      </Container>
    );
  }

  if (!testData) {
    return (
      <Container maxWidth="lg" sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h4" color="error">
          Test not found
        </Typography>
      </Container>
    );
  }

  const handleAddToCart = () => {
    addItem({
      item: testData._id,
      itemType:"Test"
    });
    showNotification('Test added to cart successfully');
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Section elevation={1}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
          <Box>
            <Typography variant="h4" gutterBottom>
              {testData.name}
            </Typography>
            <Typography variant="h5" color="primary">
              ₹{testData.price}
            </Typography>
          </Box>
          <Button
            variant="contained"
            size="large"
            startIcon={<AddShoppingCart />}
            onClick={handleAddToCart}
            sx={{
              px: 4,
              py: 1.5,
              mt: { xs: 2, sm: 0 },
              '&:hover': { backgroundColor: 'primary.dark' },
            }}
          >
            Add to Cart
          </Button>
        </Box>
      </Section>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Section elevation={1}>
            <Typography variant="h5" gutterBottom>
              Test Overview
            </Typography>
            <Typography variant="body1">{testData.description}</Typography>
          </Section>
          <Section elevation={1}>
            <Typography variant="h5" gutterBottom>
              Parameters Tested
            </Typography>
            <List>
              {testData.parameters?.length > 0 ? (
                testData.parameters.map((param, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Science color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={param} />
                  </ListItem>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No parameters available.
                </Typography>
              )}
            </List>
          </Section>
        </Grid>
        <Grid item xs={12} md={6}>
          <Section elevation={1}>
            <Typography variant="h5" gutterBottom>
              Test Preparation
            </Typography>
            <List>
              {testData.preparation?.length > 0 ? (
                testData.preparation.map((prep, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Check color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={prep} />
                  </ListItem>
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No preparation instructions available.
                </Typography>
              )}
            </List>
          </Section>
          <Section elevation={1}>
            <Typography variant="h5" gutterBottom>
              Turnaround Time
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 2 }}>
              <AccessTime color="primary" />
              <Typography variant="body1">{testData.turnaroundTime || 'Not specified'}</Typography>
            </Box>
          </Section>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BloodTestDetails;
