import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Chip,
  Stack,
  InputAdornment,
  Alert,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Save as SaveIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { useHealthPackage } from "../../context/HealthPackageContext";
import { HealthPackage } from "../../data/initialPackages";
import { useImages } from "../../context/ImageContext";
import { useNotification } from "../../context/NotificationContext";

const HealthPackageManager: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { uploadImage } = useImages();

  const { packages, addPackage, updatePackage, deletePackage, saveChanges } =
    useHealthPackage();
  const [openDialog, setOpenDialog] = useState(false);
  const [editingPackage, setEditingPackage] = useState<number | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });
  const [formData, setFormData] = useState<HealthPackage>({
    _id: 0,
    packageName: "",
    description: "",
    price: 0,
    imgUrl: "",
    includeTests: [],
    category: "basic",
    ageGroup: "18+ years",
    gender: "all",
    duration: "2-3 hours",
  });
  const [newTest, setNewTest] = useState("");
  const [test, setTest] = useState<any>([]);
  const { showNotification } = useNotification();
  const [uploading, setUploading] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  // console.log("Packages", packages);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    try {
      setUploading(true);
      const uploadPromises = Array.from(files).map(async (file) => {
        const uploadedUrl = await uploadImage(file);
        return {
          url: uploadedUrl,
          width: 800, // Default width
          height: 400, // Default height
        };
      });

      const uploadedImages = await Promise.all(uploadPromises);

      // Set the first image as the main image
      if (uploadedImages.length > 0 && !formData.imgUrl) {
        setFormData((prev) => ({
          ...prev,
          imgUrl: uploadedImages[0].url,
        }));
      }

      showNotification("Images uploaded successfully", "success");
    } catch (error) {
      showNotification("Failed to upload images", "error");
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    const fetchTest = async () => {
      await fetch(
        "https://pos-health-backend.onrender.com/pos-health/api/test/get-all-test"
      )
        .then((res) => res.json())
        .then((data) => {
          setTest(data.tests);
          // console.log("test data : ", data.tests);
        });
    };

    fetchTest();
  }, []);

  const handleOpenDialog = (pkg?: HealthPackage) => {
    if (pkg) {
      setEditingPackage(pkg._id);
      setFormData(pkg);
    } else {
      setEditingPackage(null);
      setFormData({
        _id: Math.max(0, ...packages.map((p) => p._id)) + 1,
        packageName: "",
        description: "",
        price: 0,
        imgUrl: "",
        includeTests: [],
        category: "basic",
        ageGroup: "18+ years",
        gender: "all",
        duration: "2-3 hours",
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingPackage(null);
    setFormData({
      _id: 0,
      packageName: "",
      description: "",
      price: 0,
      imgUrl: "",
      includeTests: [],
      category: "basic",
      ageGroup: "18+ years",
      gender: "all",
      duration: "2-3 hours",
    });
    setNewTest("");
  };

  const handleSave = () => {
    if (
      !formData.packageName ||
      !formData.description ||
      formData.price <= 0 ||
      !formData.imgUrl
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in all required fields",
        severity: "error",
      });
      return;
    }

    try {
      if (editingPackage) {
        updatePackage(editingPackage, formData);
        setSnackbar({
          open: true,
          message: "Package updated successfully",
          severity: "success",
        });
      } else {
        addPackage(formData);
        setSnackbar({
          open: true,
          message: "Package added successfully",
          severity: "success",
        });
      }
      handleCloseDialog();
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error saving package",
        severity: "error",
      });
    }
  };

  const handleDelete = (id: number) => {
    if (window.confirm("Are you sure you want to delete this package?")) {
      try {
        deletePackage(id);
        setSnackbar({
          open: true,
          message: "Package deleted successfully",
          severity: "success",
        });
      } catch (error) {
        setSnackbar({
          open: true,
          message: "Error deleting package",
          severity: "error",
        });
      }
    }
  };

  const handleSaveChanges = async () => {
    try {
      await saveChanges();
      setSnackbar({
        open: true,
        message: "All changes saved successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error saving changes",
        severity: "error",
      });
    }
  };

  const handleAddTest = () => {
    if (newTest.trim()) {
      setFormData({
        ...formData,
        includeTests: [...formData.includeTests, newTest.trim()],
      });
      setNewTest("");
    }
  };

  const handleRemoveTest = (index: number) => {
    setFormData({
      ...formData,
      includeTests: formData.includeTests.filter(
        (_: string, i: number) => i !== index
      ),
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4">Health Packages Management</Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSaveChanges}
            color="secondary"
          >
            Save Changes
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog()}
          >
            Add Package
          </Button>
        </Box>
      </Box>

      <Grid container spacing={3}>
        {packages?.map((pkg: HealthPackage) => (
          <Grid item xs={12} sm={6} md={4} key={pkg._id}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <CardMedia
                component="img"
                height="200"
                image={pkg.imgUrl}
                alt={pkg.packageName}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography gutterBottom variant="h6" component="div">
                    {pkg.packageName}
                  </Typography>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => handleOpenDialog(pkg)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDelete(pkg._id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  {pkg.description}
                </Typography>
                <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
                  ₹{pkg.price}
                </Typography>
                <Box>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Included Tests:
                  </Typography>
                  <Stack direction="row" spacing={1} flexWrap="wrap">
                    {pkg.includeTests.map((test: any, index: number) => (
                      <Chip
                        key={index}
                        label={test.name}
                        size="small"
                        sx={{ mb: 1 }}
                      />
                    ))}
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingPackage ? "Edit Health Package" : "Add New Health Package"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Package Name"
              fullWidth
              required
              value={formData.packageName}
              onChange={(e) =>
                setFormData({ ...formData, packageName: e.target.value })
              }
            />
            <TextField
              label="Description"
              fullWidth
              required
              multiline
              rows={3}
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
            />
            <TextField
              label="Price"
              type="number"
              fullWidth
              required
              value={formData.price}
              onChange={(e) =>
                setFormData({ ...formData, price: Number(e.target.value) })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />
            <TextField
              select
              margin="dense"
              label="Age Group"
              fullWidth
              value={formData.ageGroup}
              onChange={(e) =>
                setFormData({ ...formData, ageGroup: e.target.value })
              }
              SelectProps={{ native: true }}
            >
              <option value="">Select Age Group</option>
              <option value="Child">Child</option>
              <option value="Teen">Teen</option>
              <option value="Adult">Adult</option>
              <option value="Senior">Senior</option>
            </TextField>
            <TextField
              select
              margin="dense"
              label="Gender"
              fullWidth
              value={formData.gender}
              onChange={(e) =>
                setFormData({ ...formData, gender: e.target.value })
              }
              SelectProps={{ native: true }}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </TextField>
            <TextField
              margin="dense"
              label="Duration"
              fullWidth
              value={formData.duration}
              onChange={(e) =>
                setFormData({ ...formData, duration: e.target.value })
              }
            />
            <Box>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              {(imagePreview || formData.imgUrl) && (
                <Box sx={{ width: "100%", maxWidth: 400, mb: 2 }}>
                  <img
                    src={imagePreview || formData.imgUrl}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: 4,
                    }}
                  />
                </Box>
              )}
              <Button
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                onClick={() => fileInputRef.current?.click()}
                disabled={uploading}
              >
                {uploading ? "Uploading..." : "Upload Images"}
              </Button>
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Included Tests
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>Select Test</InputLabel>
                  <Select
                    value={newTest}
                    onChange={(e) => setNewTest(e.target.value)}
                    label="Select Test"
                  >
                    {test.map((t: any) => (
                      <MenuItem key={t._id} value={t._id}>
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button variant="outlined" onClick={handleAddTest}>
                  Add
                </Button>
              </Box>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {formData?.includeTests?.map((test: any, index: any) => (
                  <Chip
                    key={index}
                    label={test.name}
                    onDelete={() => handleRemoveTest(index)}
                    sx={{ mb: 1 }}
                  />
                ))}
              </Stack>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            {editingPackage ? "Update" : "Add"} Package
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          severity={snackbar.severity}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default HealthPackageManager;
