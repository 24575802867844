import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useHealthRisk } from 'context/HealthRiskContext';
import {
  Favorite,
  BloodtypeOutlined,
  BiotechOutlined,
  WaterDrop,
  HealthAndSafety,
  Opacity
} from '@mui/icons-material';

const RiskTile = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'transform 0.2s, box-shadow 0.2s',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(0.5),
}));

// Mapping of risk categories to icons
const riskIcons: Record<string, JSX.Element> = {
  'Heart Disease': <Favorite fontSize="large" />,
  'Diabetes': <BiotechOutlined fontSize="large" />,
  'Kidney Disease': <WaterDrop fontSize="large" />,
  'Thyroid': <HealthAndSafety fontSize="large" />,
  'Liver': <Opacity fontSize="large" />,
  'Arthritis': <BloodtypeOutlined fontSize="large"/>
};

const HealthRiskSection: React.FC = () => {
  const navigate = useNavigate();
  const { risks } = useHealthRisk();

  const handleRiskClick = (riskTitle: string) => {
    navigate(`/risk-tests/${riskTitle}`);
  };

  return (
    <Box sx={{ pt: 4, pb: 2, px: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <HealthAndSafety sx={{ color: 'primary.main' }} />
          <Typography 
            variant="h6" 
            sx={{ fontSize: '1.1rem', fontWeight: 600 }}
          >
            Health Risk
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {risks.map((item) => (
          <Grid item xs={6} sm={4} md={2} key={item._id}>
            <RiskTile elevation={1} onClick={() => handleRiskClick(item._id)}>
              <IconWrapper>
                {riskIcons[item.categoryName] || <HealthAndSafety fontSize="large" />} 
                {/* Default icon if category not found */}
              </IconWrapper>
              <Typography 
                variant="body2" 
                sx={{ fontSize: '0.85rem', fontWeight: 500, color: 'text.primary' }}
              >
                {item.categoryName}
              </Typography>
            </RiskTile>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HealthRiskSection;
