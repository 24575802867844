import React, { useState, useEffect } from 'react';
import {Box,Typography,Button,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,IconButton,Dialog,DialogTitle,DialogContent,DialogActions,TextField,Stack,Chip,InputAdornment,} from '@mui/material';
import {Edit as EditIcon,Delete as DeleteIcon,Add as AddIcon,Publish as PublishIcon,Unpublished as UnpublishedIcon,} from '@mui/icons-material';
import { useSpecialityTest } from 'context/SpecialityTestContext';

interface SpecialityTest {
  _id: string;
  name: string;
  price: number;
  description: string;
  parameters: string[];
  preparation: string[];
  turnaroundTime: string;
  published: boolean;
}

const SpecialityTestManager: React.FC = () => {
  const { tests, addTest, updateTest, deleteTest, toggleSpecialityTestPublish, loading } = useSpecialityTest();
  const [openDialog, setOpenDialog] = useState(false);
  const [editingTest, setEditingTest] = useState<string | null>(null);
  const [formData, setFormData] = useState<SpecialityTest>({
    _id: '',
    name: '',
    price: 0,
    description: '',
    parameters: [],
    preparation: [],
    turnaroundTime: '',
    published: false,
  });
  const [newParameter, setNewParameter] = useState('');
  const [newPreparation, setNewPreparation] = useState('');

  useEffect(() => {
    if (editingTest) {
      const testToEdit = tests.find((test) => test._id === editingTest);
      if (testToEdit) {
        setFormData({
          ...testToEdit,
          parameters: testToEdit.parameters || [], // Ensure parameters is an array
          preparation: testToEdit.preparation || [], // Ensure preparation is an array
        });
      }
    }
  }, [editingTest, tests]);
  
  const handleOpenDialog = (test?: SpecialityTest) => {
    if (test) {
      setEditingTest(test._id);
      setFormData({
        ...test,
        parameters: test.parameters || [],
        preparation: test.preparation || [],
      });
    } else {
      setEditingTest(null);
      setFormData({
        _id: '',
        name: '',
        price: 0,
        description: '',
        parameters: [],
        preparation: [],
        turnaroundTime: '',
        published: false,
      });
    }
    setOpenDialog(true);
  };  

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingTest(null);
    setFormData({ _id: '', name: '', price: 0, description: '', parameters: [], preparation: [], turnaroundTime: '', published: false });
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.name.trim() || !formData.price || !formData.description.trim()) {
      alert('Please fill in all required fields.');
      return;
    }
    try {
      if (editingTest) {
        await updateTest(formData); // Update the test
      } else {
        await addTest(formData); // Add a new test
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error processing the test:', error);
      alert('Something went wrong. Please try again.');
    }
  };  

  const handleDelete = (testId: string) => {
    if (window.confirm('Are you sure you want to delete this test?')) {
      deleteTest(testId);
    }
  };

  const handleAddParameter = () => {
    if (newParameter.trim()) {
      setFormData({
        ...formData,
        parameters: [...formData.parameters, newParameter.trim()],
      });
      setNewParameter('');
    }
  };
  
  const handleAddPreparation = () => {
    if (newPreparation.trim()) {
      setFormData({
        ...formData,
        preparation: [...formData.preparation, newPreparation.trim()],
      });
      setNewPreparation('');
    }
  };
  
  const handleRemoveParameter = (index: number) => {
    setFormData({
      ...formData,
      parameters: formData.parameters.filter((_, i) => i !== index),
    });
  };
  const handleRemovePreparation = (index: number) => {
    setFormData({
      ...formData,
      preparation: formData.preparation.filter((_, i) => i !== index),
    });
  };
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h5">Speciality Tests Management</Typography>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleOpenDialog()}>
          Add New Speciality Test
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tests?.map((test) => (
              <TableRow key={test._id}>
                <TableCell>{test.name}</TableCell>
                <TableCell>{test.description}</TableCell>
                <TableCell>₹{test.price}</TableCell>
                <TableCell>
                  <Chip label={test.published ? 'Published' : 'Draft'} color={test.published ? 'success' : 'default'}/>
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => toggleSpecialityTestPublish(test._id)} color={test.published ? 'success' : 'default'}title={test.published ? 'Unpublish' : 'Publish'}>
                    {test.published ? <UnpublishedIcon /> : <PublishIcon />}
                  </IconButton>
                  <IconButton onClick={() => handleOpenDialog(test)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(test._id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>{editingTest ? 'Edit Speciality Test' : 'Add New Speciality Test'}</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField label="Test Name" fullWidth value={formData.name}onChange={(e) => setFormData({ ...formData, name: e.target.value })}/>
            <TextField label="Description" fullWidth multiline rows={3} value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
            <TextField label="Price" type="number" fullWidth value={formData.price}
              onChange={(e) => setFormData({ ...formData, price: Number(e.target.value) })}
              InputProps={{startAdornment: <InputAdornment position="start">₹</InputAdornment>,}}
            />
            <TextField label="Turnaround Time"fullWidth value={formData.turnaroundTime}onChange={(e) => setFormData({ ...formData, turnaroundTime: e.target.value })}/>
            <Box>
              <Typography variant="subtitle1">Parameters</Typography>
              <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                <TextField label="Add Parameter"size="small"value={newParameter}onChange={(e) => setNewParameter(e.target.value)}fullWidth/>
                <Button variant="outlined" onClick={handleAddParameter}>
                  Add
                </Button>
              </Box>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {(formData.parameters ?? []).map((param, index) => (
                  <Chip key={index}label={param}onDelete={() => handleRemoveParameter(index)}sx={{ mb: 1 }}/>
                ))}
              </Stack>
            </Box>
            <Box>
              <Typography variant="subtitle1">Preparation</Typography>
              <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                <TextField label="Add Preparation Instruction" size="small" value={newPreparation} onChange={(e) => setNewPreparation(e.target.value)}fullWidth/>
                <Button variant="outlined" onClick={handleAddPreparation}>
                  Add
                </Button>
              </Box>
              <Stack direction="row" spacing={1} flexWrap="wrap">
              {(formData.parameters ?? []).map((param, index) => (
  <Chip key={index} label={param} onDelete={() => handleRemoveParameter(index)} sx={{ mb: 1 }} />
))}

{(formData.preparation ?? []).map((prep, index) => (
  <Chip key={index} label={prep} onDelete={() => handleRemovePreparation(index)} sx={{ mb: 1 }} />
))}
              </Stack>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">
            {editingTest ? 'Update' : 'Add'} Test
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default SpecialityTestManager;
