import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

export interface Blog {
  _id: string;
  title: string;
  content: string;
  category: string;
  imgUrl: string;
  imgWidth: number;
  imgHeight: number;
  author: string;
  publishDate: string;
  summary: string;
  tags: string[];
  status: 'draft' | 'published';
}

interface BlogContextType {
  blogs: Blog[];
  addBlog: (blog: Omit<Blog, '_id'>) => void;
  updateBlog: (id: string, blog: Partial<Blog>) => void;
  deleteBlog: (id: string) => void;
  getBlogById: (id: string) => Blog | undefined;
  publishBlog: (id: string) => void;
  unpublishBlog: (id: string) => void;
}

const BlogContext = createContext<BlogContextType | undefined>(undefined);

export const BlogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [cookie] = useCookies(["adminToken"]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          'https://fapi.pshealth.in/pos-health/api/blog/get-all'
        );

        if (!response.ok) {
          console.error('Failed to fetch blogs:', response.statusText);
          return;
        }

        const data = await response.json();
        setBlogs(data.blogs);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

      fetchBlogs();
  }, []);

  const addBlog = async (blog: Omit<Blog, '_id'>) => {
    try {
      const response = await fetch(
        'https://fapi.pshealth.in/pos-health/api/blog/add-new',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(blog),
        }
      );

      if (!response.ok) {
        console.error('Failed to add blog:', await response.text());
        return;
      }

      const newBlog = await response.json();
      if (newBlog && newBlog._id) {
        setBlogs((prev = []) => [...prev, newBlog.newBlog]);
      }
    } catch (error) {
      console.error('Error adding blog:', error);
    }
  };

  const updateBlog = async (id: string, updatedBlog: Partial<Blog>) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/blog/update/${id}`,
        {
          method: 'PATCH',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(updatedBlog),
        }
      );

      if (!response.ok) {
        console.error('Failed to update blog:', await response.text());
        return;
      }

      setBlogs((prev) =>
        prev.map((blog) => (blog._id === id ? { ...blog, ...updatedBlog } : blog))
      );
    } catch (error) {
      console.error('Error updating blog:', error);
    }
  };

  const deleteBlog = async (id: string) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/blog/delete/${id}`,
        {
          method: 'DELETE',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );

      if (!response.ok) {
        console.error('Failed to delete blog:', await response.text());
        return;
      }

      setBlogs((prev) => prev.filter((blog) => blog._id !== id));
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  const getBlogById = (id: string) => {
    return blogs.find((blog) => blog._id === id);
  };

  const publishBlog = async (id: string) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/blog/publish/${id}`,
        {
          method: 'PATCH',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );

      if (!response.ok) {
        console.error('Failed to publish blog:', await response.text());
        return;
      }

      setBlogs((prev) =>
        prev.map((blog) =>
          blog._id === id
            ? { ...blog, status: 'published', publishDate: new Date().toISOString() }
            : blog
        )
      );
    } catch (error) {
      console.error('Error publishing blog:', error);
    }
  };

  const unpublishBlog = async (id: string) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/blog/unpublish/${id}`,
        {
          method: 'PATCH',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );

      if (!response.ok) {
        console.error('Failed to unpublish blog:', await response.text());
        return;
      }

      setBlogs((prev) =>
        prev.map((blog) =>
          blog._id === id ? { ...blog, status: 'draft' } : blog
        )
      );
    } catch (error) {
      console.error('Error unpublishing blog:', error);
    }
  };

  return (
    <BlogContext.Provider
      value={{
        blogs,
        addBlog,
        updateBlog,
        deleteBlog,
        getBlogById,
        publishBlog,
        unpublishBlog,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};

export const useBlog = () => {
  const context = useContext(BlogContext);
  if (!context) {
    throw new Error('useBlog must be used within a BlogProvider');
  }
  return context;
};
