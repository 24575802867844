import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  HomeCollectionRequest,
  HomeCollectionStatus,
} from "../types/homeCollection";
import { useCookies } from "react-cookie";

// Generate a random ID with timestamp and random number
const generateId = () => {
  return `hc_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
};

interface HomeCollectionContextType {
  requests: HomeCollectionRequest[];
  addRequest: (
    request: Omit<
      HomeCollectionRequest,
      "_id" | "status" | "createdAt" | "updatedAt" | "patient"
    >,
    token: any
  ) => void;
  updateRequestStatus: (id: string, status: HomeCollectionStatus) => void;
  deleteRequest: (id: string) => void;
  getUnreadRequestsCount: () => number;
  markRequestAsRead: (id: string) => void;
}

const HomeCollectionContext = createContext<
  HomeCollectionContextType | undefined
>(undefined);

// Local storage keys
const REQUESTS_STORAGE_KEY = "homeCollectionRequests";
const READ_REQUESTS_STORAGE_KEY = "homeCollectionReadRequests";

export const HomeCollectionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Initialize state from localStorage
  const [requests, setRequests] = useState<HomeCollectionRequest[]>([]);

  const [readRequests, setReadRequests] = useState<Set<string>>(() => {
    const savedReadRequests = localStorage.getItem(READ_REQUESTS_STORAGE_KEY);
    return new Set(savedReadRequests ? JSON.parse(savedReadRequests) : []);
  });

  const [cookie] = useCookies(["adminToken"]);


  // Save to localStorage whenever state changes
  useEffect(() => {

    localStorage.setItem(REQUESTS_STORAGE_KEY, JSON.stringify(requests));
  }, [requests]);

  useEffect(() => {
    localStorage.setItem(
      READ_REQUESTS_STORAGE_KEY,
      JSON.stringify(Array.from(readRequests))
    );
  }, [readRequests]);

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const response = await fetch(
          "https://fapi.pshealth.in/pos-health/api/home-collection/get-all-request",{
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${cookie.adminToken}`,
            },
          }
        );
        const data = await response.json();
        setRequests(data.data);

        // console.log("home collection request : ",data);
      } catch (error) {
        console.error(
          "somethin went wrong while fetching home collection data : "
        );
      }
    };

    fetchRequest();
  }, []);

  const addRequest = async (request: any, token: any) => {
    try {
      const response = await fetch(
        "https://fapi.pshealth.in/pos-health/api/home-collection/request",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(request),
        }
      );
      const data = await response.json();
      console.log("new collection : ",data);
      setRequests((prev=[]) => [...prev, data.data]);
    } catch (error) {
      console.error("Error adding home collection request:", error);
    }
  };

  const updateRequestStatus = async (
    id: string,
    status: HomeCollectionStatus,
  ) => {
    try {
      console.log("status : ", status);
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/home-collection/update-request/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify({ status }),
        }
      );
      if (!response.ok) throw new Error("Failed to update status");

      setRequests((prev: any) =>
        prev.map((req: any) => (req._id === id ? { ...req, status } : req))
      );
    } catch (error) {
      console.error("Error updating request status:", error);
    }
  };

  const deleteRequest = async (id: string) => {
    try {
      const response = await fetch(
        `https://fapi.pshealth.in/pos-health/api/home-collection/delete-request/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to delete request");

      setRequests((prev) => prev.filter((req) => req._id !== id));
    } catch (error) {
      console.error("Error deleting request:", error);
    }
  };

  const getUnreadRequestsCount = useCallback(() => {
    return requests?.filter((request) => !readRequests.has(request._id)).length;
  }, [requests, readRequests]);

  const markRequestAsRead = useCallback((id: string) => {
    setReadRequests((prev) => new Set(Array.from(prev).concat([id])));
  }, []);

  return (
    <HomeCollectionContext.Provider
      value={{
        requests,
        addRequest,
        updateRequestStatus,
        deleteRequest,
        getUnreadRequestsCount,
        markRequestAsRead,
      }}
    >
      {children}
    </HomeCollectionContext.Provider>
  );
};

export const useHomeCollection = () => {
  const context = useContext(HomeCollectionContext);
  if (context === undefined) {
    throw new Error(
      "useHomeCollection must be used within a HomeCollectionProvider"
    );
  }
  return context;
};
