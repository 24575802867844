import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box, CircularProgress, Link } from '@mui/material';
import { useNotification } from '../../context/NotificationContext';
import { Link as RouterLink } from 'react-router-dom';
import { useCookies } from "react-cookie";

interface LoginDialogProps {
  open: boolean;
  onClose: () => void;
  onOpenSignUp: () => void;
}

const Login: React.FC<LoginDialogProps> = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState<'email' | 'profile'>('email');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();

  const [cookies, setCookie] = useCookies(['userToken']); 

  const loginAPI = async (email: string, password: string) => {
    try {
      const response = await fetch('https://fapi.pshealth.in/pos-health/api/patient/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      return data;
    } catch (error) {
      throw new Error('An error occurred while logging in');
    }
  };

  const handleMobileSubmit = async () => {
    if (!email || !password) {
      showNotification('Please enter your email or password', 'error');
      return;
    }

    setLoading(true);
    try {
      const data = await loginAPI(email, password);

      // Assuming the response contains a JWT token
      const token = data.token;
      const expiryDate = new Date();
      expiryDate.setHours(expiryDate.getHours() + 1);

      // Store the token in an HTTP-only cookie with a 1 hour expiration
      setCookie('userToken', token, { expires: expiryDate });

      showNotification('Login successful!', 'success');
      setStep('profile');
    } catch (error: any) {
      showNotification(error.message || 'Failed to login. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    if (!loading) {
      setStep('email');
      setEmail('');
      onClose();
    }
  };

  const handleProfileSuccess = () => {
    handleDialogClose();
    showNotification('Login successful! Welcome to PS Healthcare', 'success');
  };

  const handleOpenSignUp = () => {
    navigate('/signup');
  };

  // Handle Logout (clear cookie)
  const handleLogout = () => {
    setCookie('userToken', '', { expires: new Date(0) });
    showNotification('Logged out successfully', 'success');
    // Redirect to login page or home
    navigate('/');
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2,
        },
      }}
    >
      {step === 'email' && (
        <>
          <DialogTitle sx={{ pb: 1 }}>Login / Sign Up</DialogTitle>
          <DialogContent>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}></Typography>
            <TextField
              autoFocus
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter your email"
              disabled={loading}
            />
            <TextField
              fullWidth
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Enter your password"
              disabled={loading}
            />
            <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block', textAlign: 'center' }} >
              By proceeding, you agree to PS Healthcare's{' '}
              <Link component={RouterLink} to="/terms" onClick={() => window.open('/terms', '_blank')} sx={{ cursor: 'pointer', '&:hover': { color: 'primary.main' } }}>
                T&C
              </Link>{' '}
              and{' '}
              <Link component={RouterLink} to="/privacy" onClick={() => window.open('/privacy', '_blank')} sx={{ cursor: 'pointer', '&:hover': { color: 'primary.main' } }}>
                Privacy Policy
              </Link>
            </Typography>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 2 }}>
            <Button onClick={handleDialogClose} disabled={loading}>
              Cancel
            </Button>
            <Button onClick={handleMobileSubmit} variant="contained" disabled={loading || !email || !password} sx={{ minWidth: 100 }}>
              {loading ? <CircularProgress size={24} /> : 'Login'}
            </Button>
          </DialogActions>
          <Box sx={{ px: 3, pb: 2, textAlign: 'center' }}>
            <Typography variant="body2">
              Don't have an account?{' '}
              <Button
                onClick={handleOpenSignUp}
                color="primary"
                sx={{ padding: 0 }}
              >
                Sign Up
              </Button>
            </Typography>
          </Box>
        </>
      )}
      {/* {step === 'profile' && (
        <ProfileForm mobile={email} onSuccess={handleProfileSuccess} />
      )} */}
    </Dialog>
  );
};

export default Login;
