import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";


interface SpecialityTest {
  _id: string;
  name: string;
  price: number;
  description: string;
  parameters: string[];
  preparation: string[];
  turnaroundTime: string;
  published: boolean;
}

// Define the context type
interface SpecialityTestContextType {
  tests: SpecialityTest[];
  addTest: (test: SpecialityTest) => void;
  updateTest: (test: SpecialityTest) => void;
  deleteTest: (id: string) => void;
  toggleSpecialityTestPublish: (id: string) => void;
  openEditDialog: (test: SpecialityTest) => void;
  closeEditDialog: () => void;
  selectedTest: SpecialityTest | null;
  loading: boolean;
}

// Create the context
const SpecialityTestContext = createContext<SpecialityTestContextType | undefined>(undefined);

export const SpecialityTestProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tests, setTests] = useState<SpecialityTest[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTest, setSelectedTest] = useState<SpecialityTest | null>(null); // To store the test being edited
  const [cookie] = useCookies(["adminToken"]);

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const res = await axios.get(
          "https://fapi.pshealth.in/pos-health/api/speciality-test/get-all",
          { withCredentials: true }
        );
        if (res.data && res.data.test) {
          setTests(res.data.test);
          // console.log("s data : ",res.data);
        } else {
          console.warn("No tests found in response:", res.data);
        }
      } catch (error: unknown) {
        console.error("Error fetching tests:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTests();
  }, []);

  const addTest = async (test: SpecialityTest) => {
    try {
      const newTest = {
        name: test.name,
        description: test.description,
        testPreparation: Array.isArray(test.preparation) ? test.preparation : [],
        parameterTested: Array.isArray(test.parameters) ? test.parameters : [],
        price: test.price,
        turnaroundTime: test.turnaroundTime,
      };

      const res = await fetch(
        "https://fapi.pshealth.in/pos-health/api/speciality-test/add-new",
        {
          method:"POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(newTest),
        }
      );

      const result = await res.json();

      if (result.data) {
        setTests((prevTests=[]) => [...prevTests, result.data]);
      }
    } catch (err) {
      console.error("Failed to add the test:", err);
    }
  };
  const updateTest = async (updatedTest: SpecialityTest) => {
    try {
      const res = await fetch(
        `https://fapi.pshealth.in/pos-health/api/speciality-test/update/${updatedTest._id}`,
        {
          method:"PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
          body: JSON.stringify(updatedTest),
        }
      );
      const result = await res.json();
      if (res.status === 200) {
        setTests((prevTests) =>
          prevTests.map((test) =>
            test._id === updatedTest._id ? { ...test, ...result.data } : test
          )
        );
      }
    } catch (err) {
      console.error("Error updating test:", err);
    }
  };
  const deleteTest = async (id: string) => {
    try {
      await fetch(
        `https://fapi.pshealth.in/pos-health/api/speciality-test/delete/${id}`,
        {
          method:"DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );

      setTests((prevTests) => prevTests.filter((test) => test._id !== id));
    } catch (err) {
      console.error("Error deleting test:", err);
    }
  };
  
  const toggleSpecialityTestPublish = async (id: string) => {
    try {
      const res = await fetch(
        `https://fapi.pshealth.in/pos-health/api/speciality-test/toggle-publish/${id}`,
        {
          method: 'PATCH',
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookie.adminToken}`,
          },
        }
      );
      const result = await res.json();

      // console.log("updating res : ",result)
      if (res.status === 200) {
        setTests((prevTests=[]) =>
          prevTests.map((test) =>
            test._id === id ? { ...test, published: result.updatedSpecialityTest.published } : test
          )
        );
      }
    } catch (err) {
      console.error("Error toggling publish status:", err);
    }
  };


  const openEditDialog = (test: SpecialityTest) => {
    setSelectedTest(test);
  };
  const closeEditDialog = () => {
    setSelectedTest(null);
  };

  return (
    <SpecialityTestContext.Provider
      value={{
        tests,
        addTest,
        updateTest,
        deleteTest,
        toggleSpecialityTestPublish,
        openEditDialog,
        closeEditDialog,
        selectedTest,
        loading,
      }}
    >
      {children}
    </SpecialityTestContext.Provider>
  );
};
export const useSpecialityTest = () => {
  const context = useContext(SpecialityTestContext);
  if (context === undefined) {
    throw new Error("useBloodTest must be used within a BloodTestProvider");
  }
  return context;
};