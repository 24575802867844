import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Grid, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { usePatient } from '../../context/PatientContext';
import { useNotification } from '../../context/NotificationContext';

const ProfileForm = ({ patientData }: any) => {
  const { updatePatient } = usePatient();
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState<any>(null);
  const { showNotification } = useNotification();

  const [formData, setFormData] = useState({
    fullName: '',
    mobileNumber: '',
    address: '',
    city: '',
    pinCode: '',
    age: 0,
    medicalHistory: [] as string[],
    password: '',
    gender: ''
  });

  useEffect(() => {
    if (patientData) {
      setPatient(patientData);
      setFormData({
        fullName: patientData.fullName || '',
        mobileNumber: patientData.mobileNumber || '',
        address: patientData.address || '',
        city: patientData.city || '',
        pinCode: patientData.pinCode || '',
        age: patientData.age || 0,
        medicalHistory: patientData.medicalHistory || [],
        password: '',
        gender: patientData.gender || ''
      });
      setLoading(false);
    }
  }, [patientData]);

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name as string]: name === 'medicalHistory' ? (value as string).split(',') : value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (patient) {
      const result = await updatePatient(patient._id, formData);

      if(result.status==200){
        showNotification('Profile Updated successful!', 'success');
      }
      setIsEditing(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', padding: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
          <Grid item>
            <Button variant="contained" onClick={() => setIsEditing(!isEditing)}>
              {isEditing ? 'Cancel' : 'Edit Profile'}
            </Button>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {['fullName', 'mobileNumber', 'address', 'city', 'pinCode'].map((field) => (
              <Grid item xs={12} sm={6} key={field}>
                <TextField
                  label={field.replace(/([A-Z])/g, ' $1').trim()}
                  name={field}
                  value={(formData as any)[field]}
                  onChange={handleChange}
                  fullWidth
                  required
                  disabled={!isEditing}
                />
              </Grid>
            ))}

            <Grid item xs={12} sm={6}>
              <TextField
                label="Age"
                name="age"
                type="number"
                value={formData.age}
                onChange={handleChange}
                fullWidth
                disabled={!isEditing}
              />
            </Grid>

            {/* Gender Dropdown */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth disabled={!isEditing}>
                <InputLabel>Gender</InputLabel>
                <Select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  label="Gender"
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Medical History"
                name="medicalHistory"
                value={formData.medicalHistory.join(', ')}
                onChange={handleChange}
                fullWidth
                placeholder="e.g., Diabetes, Hypertension"
                disabled={!isEditing}
              />
            </Grid>

            {isEditing && (
              <Grid item xs={12}>
                <Button type="submit" variant="contained">Save Changes</Button>
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default ProfileForm;
